import React, { useState, useEffect } from 'react';
import Login from "./Login";
import { auth } from "./firebase"
import { onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
import Home2 from "./Components/activities/Home2";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Facturacion2 from "./Components//activities/Facturacion2"
import PQR2 from "./Components//activities/PQR2";
import Ordenes2 from "./Components//activities/Ordenes2";
import Documentos2 from "./Components//activities/Documentos2";
import NuevaTarea2 from "./Components//activities/NuevaTarea2";
import Orden2 from "./Components//activities/Orden2";
import NewClient2 from "./Components//activities/NewClient2";
import Instalacion2 from "./Components//activities/Instalacion2";
import Factura2 from "./Components//activities/Factura2";
import Search2 from "./Components//activities/Search2";
import Spinner from "./Components//activities/Spinner";
import EditFact2 from "./Components//activities/EditFact2";
import Collaborator from "./Components//activities/NewColaborate2";
import Pdf from "./Components//activities/pdf";
import Principal2 from "./Components//activities/Principal2";
import Chat2 from "./Components//activities/Chat2";
import Cnt2 from "./Components//activities/Cntcaja2";
import Navbar from "./Components/common/Navbar";


export default function App() {
  const [user, setUser] = React.useState(null);
  const [authState, setAuthState] = React.useState(null);
  const [constante, setConstante] = useState(null);



  React.useEffect(() => {
    const unSubscribeAuth = onAuthStateChanged(auth, async authenticatedUser => {
      if (authenticatedUser) {
        setUser(authenticatedUser.email);
      } else {
        setUser(null);
      }
    });

    return unSubscribeAuth;
  }, []);

  React.useEffect(() => {
    if (user) {
      setAuthState('search');
    } else {
      setAuthState('Login');
    }
  }, [user]);

  if (authState === null) return <Spinner />
  if (authState === 'Login') return <Login setAuthState={setAuthState} setUser={setUser} />

  if (user) return (


    <BrowserRouter>

       <Routes>
        <Route path="/" element={<Principal2 />} />
        <Route path="/principal" element={<Principal2 user={user}/>} />
        <Route path="/home" element={<Home2 user={user}/>} />
        <Route path="/pqr" element={<PQR2 user={user} />} />
        <Route path="/documentos" element={<Documentos2 />} />
        <Route path="/facturacion" element={<Facturacion2 user={user} />} />
        <Route path="/ordenes" element={<Ordenes2 />} />
        <Route path="/orden" element={<Orden2 user={user} />} />
        <Route path="/tarea" element={<NuevaTarea2 user={user} />} />
        <Route path="/instalacion" element={<Instalacion2 user={user} />} />
        <Route path="/crear" element={<NewClient2 user={user}/>} />
        <Route path="/factura" element={<Factura2 user={user} />} />
        <Route path="/edit" element={<EditFact2 user={user} />} />
        <Route path="/cnt" element={<Cnt2 user={user} />} />
        <Route path="/nav" element={<Navbar  />} />
        <Route path="/search" element={<Search2 />} />
        <Route path="/pdf" element={<Pdf />} />
        <Route path="/chat" element={<Chat2 />} />
        <Route path="/collaborator" element={<Collaborator />} />
     
      </Routes>
    </BrowserRouter>)

}