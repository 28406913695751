import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { getFirestore, collection, addDoc, getDoc, doc, deleteDoc, getDocs, setDoc } from "firebase/firestore";
import { FiPlus, FiX } from "react-icons/fi"
import Select from 'react-select';
import { FaAngleDown, FaWifi, FaStoreAlt } from 'react-icons/fa';
import DatePickerComponent from './Datepicker1';
import { getDatabase, ref, set } from "firebase/database";
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function EditFact(props) {

    const userLogin = props.user;
    console.log(userLogin);

    const constanteRecibida = props.constante;

    const location = useLocation();
    const detail = location.state ? location.state.detail : null;
    console.log(userLogin);
    console.log(detail);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenApp, setIsOpenApp] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionApp, setSelectedOptionApp] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [fechaLimitePago, setFechaLimitePago] = useState(null);
    const [userEstado, setUserEstado] = useState("");
    const [userPlan, setUserPlan] = useState("");
    const [userMensualidad, setUserMensualidad] = useState("");
    const [userMedioDePago, setUserMedioDePago] = useState("");
    const [fechaFacturacionCompleta, setFechaFacturacionCompleta] = useState("");
    const [numeroUnicoFactura, setNumeroUnicoFactura] = useState('');
    const [total, setTotal] = useState("");
    const navigate = useNavigate();

    const crearNodosFacturas = async () => {
        const db = getDatabase();

        const nodosFacturas = ['pendientes', 'pagadas', 'acuerdoPago', 'anuladas'];

        for (const nodo of nodosFacturas) {
            const nodoRef = ref(db, `facturas/${nodo}`);
            await set(nodoRef, {}); // Crear el nodo vacío si no existe
        }
    };

    const handleTotalChange = (e) => {
        setTotal(e.target.value);
    };

    useEffect(() => {
        if (numeroUnicoFactura) {
            setValorInicial((prevState) => ({
                ...prevState,
                codigo: numeroUnicoFactura
            }));
        }
    }, [numeroUnicoFactura]);


    const [valorInicial, setValorInicial] = useState({
        fechaCompleta: "",
        fechaLimitePago: "",
        chat: "",
        llamada: "",
        codigo: numeroUnicoFactura,
        cedula: detail?.documento,
        primerNombre: detail?.primerNombre,
        segundoNombre: detail?.segundoNombre,
        primerApellido: detail?.primerApellido,
        segundoApellido: detail?.segundoApellido,
        mensualidad: "",
        estado: "",
        medio_de_pago: "",
        comentarios: "",
        total: "",
        usuario: userLogin,

    });


    useEffect(() => {
        // Obtener la fecha actual
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Obtener el mes (0-11) y agregar cero a la izquierda si es necesario
        const day = String(today.getDate()).padStart(2, '0'); // Obtener el día y agregar cero a la izquierda si es necesario

        // Formatear la fecha como YYYYMMDD
        const fechaNumerica = `${year}${month}${day}`;

        // Generar un número aleatorio de 6 dígitos
        const numeroAleatorio = Math.floor(1000 + Math.random() * 9000);

        // Concatenar la fecha numerica y el número aleatorio para formar el número único de factura
        const numeroFactura = `${fechaNumerica}${numeroAleatorio}`;

        // Establecer el número único de factura en el estado
        setNumeroUnicoFactura(numeroFactura);
    }, []);


    const estado = [
        { label: "pendientes", value: "pendientes" },
        { label: "pagadas", value: "pagadas" },
        { label: "acuerdo de Pago", value: "acuerdoPago" },
        { label: "anuladas", value: "anuladas" }




    ]

    const medio_de_pago = [
        { label: "Efectivo", value: "Efectivo" },
        { label: "Tarjeta Debito", value: "Tarjeta Debito" },
        { label: "Tarjeta Credito", value: "Tarjeta Credito" },
        { label: "Nequi", value: "Nequi" },
        { label: "DaviPlata", value: "DaviPlata" },
        { label: "Plataforma de Pagos", value: "Plataforma de Pagos" },




    ]

    const mensualidad = [
        { label: "Enero ", value: "Enero" },
        { label: "Febrero", value: "Febrero " },
        { label: "Marzo", value: "Marzo" },
        { label: "Abril", value: "Abril" },
        { label: "Mayo", value: "Mayo" },
        { label: "Junio", value: "Junio" },
        { label: "Julio", value: "Julio" },
        { label: "Agosto", value: "Agosto" },
        { label: "Septiembre", value: "Septiembre" },
        { label: "Octubre", value: "Octubre" },
        { label: "Noviembre", value: "Noviembre" },
        { label: "Diciembre", value: "Diciembre" }



    ]


    const plan = [
        { label: "INTERNET__20MG_$42.000", value: "INTERNET__20MG_$42.000" },
        { label: "INTERNET__50MG_$52.000", value: "INTERNET__50MG_$52.000" },
        { label: "INTERNET__100MG_$62.000", value: "INTERNET__100MG_$62.000" },
        { label: "INTERNET__300MG_$92.000", value: "INTERNET__300MG_$92.000" },
        { label: "Solo_TV_$_28.000", value: "Solo_TV_$_28.000" },
        { label: "COMBO_@+TV_20MG_$70.000", value: "COMBO_@+TV_20MG_$70.000" },
        { label: "COMBO_@+TV_50MG_$80.000", value: "COMBO_@+TV_50MG_$80.000" },
        { label: "COMBO_@+TV_100MG_$90.000", value: "COMBO_@+TV_100MG_$90.000" },
        { label: "COMBO_@+TV_300MG_$100.000", value: "COMBO_@+TV_300MG_$100.000" }


    ]






    const entrada = [
        { label: "CHAT ", value: "CHAT" },
        { label: "LLAMADA", value: "LLAMADA" }


    ]








    useEffect(() => {
        capturarFechaCreacion();


    }, []);

    const capturarFechaCreacion = () => {
        const date = new Date();

        const opcionesFecha = { year: "numeric", month: "long", day: "numeric" };
        const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

        const fechaFacturacion = date.toLocaleString(undefined, opcionesFecha);
        const horaFacturacion = date.toLocaleString(undefined, opcionesHora);

        setValorInicial((prevState) => ({
            ...prevState,

            fechaFacturacion,
            horaFacturacion,
        }));

        const fechaCompleta = fechaFacturacion + " " + horaFacturacion;
        setFechaFacturacionCompleta(fechaCompleta);

        setValorInicial((prevState) => ({
            ...prevState,
            fechaCompleta: fechaCompleta,
        }));

        console.log(fechaCompleta);

        setValorInicial((prevState) => ({
            ...prevState,
            total: total // Actualiza valorInicial con el valor del input
        }));
    };


    const handleOptionSelect = (option) => {

        setSelectedOption(option);
        setIsOpen(false);
    };






    const [user, setUser] = useState(valorInicial);








    useEffect(() => {
        localStorage.setItem('selectedTime', selectedTime);
    }, [selectedTime]);

    useEffect(() => {
        const storedTime = localStorage.getItem('selectedTime');
        if (storedTime) {
            setSelectedTime(storedTime);
        }
    }, []);









    const capturarHoraAgendamiento = (time) => {
        setSelectedTime(time);
        console.log(time);
        setValorInicial((prevState) => ({
            ...prevState,
            horaAgendamiento: time
        }));
    };


    const captureInputs = (e) => {
        const { id, value } = e.target;
        setUser({ ...user, [id]: value })
        
        console.log(user);

    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionAppSelect = (option1) => {
        setSelectedOptionApp(option1);

        setIsOpenApp(false);
    };
    const toggleDropdownApp = () => {
        setIsOpenApp(!isOpenApp);
    };





    const guardarDatos = async (e) => {
        e.preventDefault();

        try {
            if (!detail?.documento) {
                throw new Error("No se ha proporcionado el documento para la factura.");
            }

            let nodo;

            switch (valorInicial.estado) {
                case "pendiente":
                case "pendientes":
                    nodo = `facturas/pendientes/${detail.documento}/${valorInicial.codigo}`;
                    break;
                case "pagada":
                case "pagadas":
                    nodo = `facturas/pagadas/${detail.documento}/${valorInicial.codigo}`;
                    break;
                case "acuerdoPago":
                case "acuerdosPago":
                    nodo = `facturas/acuerdoPago/${detail.documento}/${valorInicial.codigo}`;
                    break;
                case "anulada":
                case "anuladas":
                    nodo = `facturas/anuladas/${detail.documento}/${valorInicial.codigo}`;
                    break;
                default:
                    throw new Error(`Estado no válido: ${valorInicial.estado}`);
            }

            // Ajustando el nodo para almacenar valorInicial correctamente
            const nodoFactura = `${nodo}`;

            await set(ref(getDatabase(), nodoFactura), valorInicial);

            setValorInicial((prevState) => ({
                ...prevState,
                fechaCreacion: "",
                horaCreacion: "",
                fechaAgendamiento: "",
                horaAgendamiento: "",
                tipo: "",
                asesores: "",
                solution: "",
                codigo: numeroUnicoFactura,
                documento: "",
                nombres: "",
                apellidos: "",
                zona: "",
                estado: "",
                falla: "",
                total: "",
                comentarios: ""
            }));

            toast.success("Orden creada correctamente");
            navigate("/facturacion");

        } catch (error) {
            console.error("Error al guardar en Firebase:", error);
            toast.error("Error en orden: " + error.message);
        }
    };






   
    

    const handleSelectEstado = ({ value }) => {
        setValorInicial((prevState) => ({
            ...prevState,
            estado: value
        }));


    };

    const handleFechaLimitePagoChange = (selectedDate) => {


        setValorInicial((prevState) => ({
            ...prevState,
            fechaLimitePago: selectedDate


        }
        ));
    }

    const handleSelectMedioDePago = ({ value }) => {
        setValorInicial((prevState) => ({
            ...prevState,
            medio_de_pago: value
        }));


    };



    const handleSelectMensualidad = ({ value }) => {
        console.log(value); // Verifica que recibas el valor del mes correctamente
        setValorInicial(prevState => ({
            ...prevState,
            mensualidad: value
        }));

    };

    const handleSelectPlan = ({ value }) => {
        console.log(value);
        setValorInicial((prevState) => ({
            ...prevState,
            plan: value
        }));


    };




    return (
        <div>

            <div>
                <ToastContainer />
                <div className="px-4 md:px-10 py-4 md:py-7 bg-pack-six bg-cover bg-center  rounded-tl-lg rounded-tr-lg">
                    <div className="sm:flex items-center justify-between">
                        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Informacion General</p>
                        <div>
                            <button onclick="popuphandler(true)" className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-500 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/40 text-white font-semibold rounded-lg">
                            </button>
                        </div>
                    </div>
                </div>
                <div id="popup" className="inset-0  z-50 right-100 w-auto py-12">
                    <div className="flex w-full justify-center ">
                        <div className="bg-white  dark:bg-gray-800 shadow-lg  rounded-2xl  shadow-blue-300  w-full md:w-11/12 pt-10 px-10 max-w-4xl z-50">
                            <div className="flex flex-col w-full  justify-between">
                                <form onSubmit={guardarDatos}>




                                    <table className="w-full text-left mb-10 border-2 border-blue-400">
                                        <div className="flex w-full h-20 justify-between items-center">
                                            <h2 className="ml-3 text-4xl font-bold dark:text-gray-100 text-gray-900">Factura($)</h2>
                                            <h1  className='text-4xl mr-3 text-red-600'>#{numeroUnicoFactura}</h1>


                                        </div>
                                        <tbody>

                                            <tr className="border-t border-blue-300">
                                                <td className="p-3" colSpan="4">
                                                    <div className="text-center font-bold">
                                                        <h1>Fechas de Facturacion</h1>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="border-t border-blue-300">
                                                <td className="p-3" colSpan="2">
                                                    <div className="flex py-10 gap-2 items-center justify-center">
                                                        <div className="mr-10">
                                                            <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                                                                Fecha de facturacion
                                                            </label>
                                                            <h2 className="text-center text-base from-indigo-950 rounded-2xl bg-blue-200 px-4 w-64 h-11 flex items-center">
                                                                {fechaFacturacionCompleta}
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                                                                Selecciona la fecha limite de pago
                                                            </label>
                                                            <DatePickerComponent className="mt-3" selected={valorInicial.fechaLimitePago}
                                                                onChange={handleFechaLimitePagoChange} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="border-t border-blue-300">
                                                <td className="p-3" colSpan="4">
                                                    <div className="text-center font-bold">
                                                        <h1>Confirma los datos personales del usuario</h1>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="border-t border-blue-300">
                                                <td className="p-3" colSpan="4">
                                                    <div className="grid grid-rows-2 py-2 mb-2">

                                                        <div className="row-span-1 grid grid-cols-3 gap-2 mt-4 justify-items-center">

                                                            <div className="flex flex-col items-center">
                                                                <h2 className="text-base font-bold text-gray-900">Niup</h2>
                                                                <h2 className="text-base dark:text-gray-100 text-black">{detail?.documento}</h2>
                                                            </div>
                                                            <div className="flex flex-col items-center">
                                                                <h2 className="text-base font-bold text-gray-900">Titular</h2>
                                                                <h2 className="text-base dark:text-gray-100 text-black">
                                                                    {`${detail?.primerNombre ?? ''} ${detail?.segundoNombre ?? ''} ${detail?.primerApellido ?? ''} ${detail?.segundoApellido ?? ''}`}
                                                                </h2>
                                                            </div>
                                                            <div className="flex flex-col items-center">
                                                                <h2 className="text-base font-bold text-gray-900">Direccion</h2>
                                                                <h2 className="text-base dark:text-gray-100 text-black">{detail?.direccion}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr className="border-t border-blue-300">
                                                <td className="p-3" colSpan="2">
                                                    <table className="w-full text-left">
                                                        <thead className="bg-blue-200">
                                                            <tr className="text-sm font-medium">
                                                                <th className="p-3">Elige el Plan</th>
                                                                <th className="p-3">Selecciona Estado de la factura</th>
                                                                <th className="p-3">Total </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className="p-3">
                                                                    <div
                                                                        id="estado"
                                                                        className="block px-2.5 pb-2.5 pt-4 w-64 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                                                                    >
                                                                        <Select
                                                                            defaultValue={{ label: "Plan", value: "Plan" }}
                                                                            options={plan}
                                                                            onChange={handleSelectPlan}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="p-3">
                                                                    <div
                                                                        id="estado"
                                                                        className="block px-2.5 pb-2.5 pt-4 w-48 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                                                                    >
                                                                        <Select
                                                                            defaultValue={{ label: "Estado", value: "Estado" }}
                                                                            options={estado}
                                                                            onChange={handleSelectEstado}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="p-3">
                                                                    <div className="relative">
                                                                        <input
                                                                            type="number"
                                                                            id="floating_outlined"
                                                                            className="block px-2.5 pb-2.5 pt-4 w-40 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                            placeholder=" "
                                                                            value={total}
                                                                            onChange={handleTotalChange}
                                                                        />
                                                                        <label
                                                                            htmlFor="floating_outlined"
                                                                            className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                                                                        >
                                                                            Total
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr className="border-t border-blue-300">
                                                <td className="p-3" colSpan="2">
                                                    <table className="w-full text-left">
                                                        <thead className="bg-blue-200">
                                                            <tr className="text-sm font-medium">
                                                                <th className="p-3">Mensualidad</th>
                                                                <th className="p-3">Medio de Pago</th>
                                                                <th className="p-3">Usuario</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                                <td className="p-3">
                                                                    <div
                                                                        id="mensualidad"
                                                                        className="block px-2.5 pb-2.5 pt-4 w-64 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                                                                    >
                                                                        <Select
                                                                            defaultValue={{ label: "Mensualidad", value: "Mensualidad" }}
                                                                            options={mensualidad}
                                                                            onChange={handleSelectMensualidad}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="p-3">
                                                                    <div
                                                                        id="medio de pago"
                                                                        className="block px-2.5 pb-2.5 pt-4 w-56 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                                                                    >
                                                                        <Select
                                                                            defaultValue={{ label: "Medio de Pago", value: "Medio de Pago" }}
                                                                            options={medio_de_pago}
                                                                            onChange={handleSelectMedioDePago}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="p-3">
                                                                    <div
                                                                        id="medio de pago"
                                                                        className="block px-2.5 pb-2.5 pt-4 w-56 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                                                                    >

                                                                        {userLogin}
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>


                                    <div className="md:flex justify-between w-full py-4 border-t border-gray-500">
                                        <div className="flex items-center">
                                            <div className="flex items-center justify-end text-gray-700 dark:text-gray-400">
                                                <div className="mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tag" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <path d="M11 3L20 12a1.5 1.5 0 0 1 0 2L14 20a1.5 1.5 0 0 1 -2 0L3 11v-4a4 4 0 0 1 4 -4h4" />
                                                        <circle cx={9} cy={9} r={2} />
                                                    </svg>
                                                </div>
                                                <div className="mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                                                        <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                                                    </svg>
                                                </div>
                                                <div className="mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <rect x={4} y={5} width={16} height={16} rx={2} />
                                                        <line x1={16} y1={3} x2={16} y2={7} />
                                                        <line x1={8} y1={3} x2={8} y2={7} />
                                                        <line x1={4} y1={11} x2={20} y2={11} />
                                                        <rect x={8} y={15} width={2} height={2} />
                                                    </svg>
                                                </div>
                                                <div className="mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <circle cx={9} cy={7} r={4} />
                                                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                        <path d="M16 11h6m-3 -3v6" />
                                                    </svg>
                                                </div>
                                                <div className="mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <rect x={3} y={4} width={18} height={4} rx={2} />
                                                        <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                        <line x1={10} y1={12} x2={14} y2={12} />
                                                    </svg>
                                                </div>
                                                <div className="mr-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <circle cx={5} cy={12} r={1} />
                                                        <circle cx={12} cy={12} r={1} />
                                                        <circle cx={19} cy={12} r={1} />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className="flex items-end px-64">



                                                <button type="button" className="px-10 flex justify-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm  py-2.5 text-center mr-5 mb-2">
                                                    <FiX className='w-5 h-5' />
                                                    <Link to="/facturacion" className='px-2'>Cancelar</Link>
                                                </button>

                                                <button
                                                    onClick={guardarDatos}
                                                    type="button"
                                                    className="flex justify-between text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-5 mb-2">
                                                    <FiPlus className='w-5 h-5' />
                                                    <h2 className='px-2'>Crear</h2>



                                                </button>

                                            </div>
                                        </div>

                                    </div>



                                </form>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
