import { faRunning,  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner1 from "../../assets/colnet.png";

export default function Spinner() {
    return (
        <div className="py-44 flex items-center justify-center">
            <img src={Spinner1} className="animate-spin w-64 h-56 text-xl"/>
          
        </div>
    );
}