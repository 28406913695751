import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { BsCurrencyDollar } from 'react-icons/bs';
import { IoMdAdd, IoMdTrash } from 'react-icons/io';
import { storage } from "../../firebase";
import { ref, uploadBytes } from "firebase/storage";
import user from "../../assets/user.png"
import wifi from "../../assets/wifi2.png"
import factur from "../../assets/factur.png"
import contact from "../../assets/contact.png"
import calendario from "../../assets/calendario.png"
import { useDropzone } from 'react-dropzone';
import { RiEditBoxLine, RiEmpathizeLine, RiLightbulbFlashLine, RiRefreshLine, RiAlertLine } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
import '../../index.js';



export default function Home(props) {

    const userLogin = props.user;
    console.log(userLogin);

    const constanteRecibida = props.constante;

    const [isClicked, setIsClicked] = useState(false);

    const [mostrarVentana, setMostrarVentana] = useState(false);
    const [mostrarVentanaPago, setMostrarVentanaPago] = useState(false);
    const [mostrarVentanaEdit, setMostrarVentanaEdit] = useState(false);
    const [mostrarVentanaTitular, setMostrarVentanaTitular] = useState(false);
    const [mostrarVentanaActive, setMostrarVentanaActive] = useState(false);
    const [mostrarVentanaReinicio, setMostrarVentanaReinicio] = useState(false);
    const [texto, setTexto] = useState('');
    const [clickCount, setClickCount] = useState(0);
    const [showInput, setShowInput] = useState(false);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionActive, setSelectedOptionActive] = useState('');
    const [selectedOptionPlan, setSelectedOptionPlan] = useState('');
    const [direccion, setDireccion] = useState("");
    const [via, setVia] = useState('');
    const [numero, setNumero] = useState("");
    const [letra, setLetra] = useState("")
    const [numeroDos, setNumeroDos] = useState("");
    const [numeroTres, setNumeroTres] = useState("");
    const [letraDos, setLetraDos] = useState("")
    const [barrio, setBarrio] = useState("");
    const [bis, setBis] = useState("");
    const [sufijo, setSufijo] = useState("");
    const [bisDos, setBisDos] = useState("");
    const [sufijoDos, setSufijoDos] = useState("");
    const [complemento, setComplemento] = useState("");
    const [complementos, setComplementos] = useState([]);
    const [fileRp, setFileRp] = useState(null);
    const [documento, setDocumento] = useState("");

    const location = useLocation();
    const detail = location.state ? location.state.detail : null;



    const onDropRp = (acceptedFiles) => {
        // Obtener el archivo seleccionado
        const selectedFileRp = acceptedFiles[0];
        // Actualizar el estado del archivo
        setFileRp(selectedFileRp);
    };

    const { getRootProps: getRootProps2, getInputProps: getInputProps2, isDragActive: isDragActive2 } = useDropzone({ onDrop: onDropRp });



    const handleViaChange = (event) => {
        setVia(event.target.value);
    }

    const Home = ({ searchResults }) => {
        // Usa los resultados de búsqueda en tu componente Home
        console.log('Resultados en Home:', searchResults);
    }




    const folderNameRp = 'recibo';

    const uploadImageRp = async (fileRp, documento) => {

        const fileName = documento
        const storageRef = ref(storage, `${folderNameRp}/${fileName}`);

        await uploadBytes(storageRef, fileRp);
        return true;

    }

    const handleDocumentChange = (e) => {
        const documento = e.target.value;
        setDocumento(documento);

    };

    const handleUploadRp = () => {
        if (fileRp && documento) {
            uploadImageRp(fileRp, documento);
            toast.success("Imagen subida exitosamente");
        } else {
            toast.error("Error al subir la imagen");
            console.log("Debes seleccionar una imagen y un nombre");
        }
    };






    const handleNumeroChange = (e) => {
        setNumero(e.target.value);
    };
    const handleLetraChange = (e) => {
        setLetra(e.target.value);
    };

    const handleNumeroDosChange = (e) => {
        setNumeroDos(e.target.value);
    };

    const handleNumeroTresChange = (e) => {
        setNumeroTres(e.target.value);
    };

    const handleLetraDosChange = (e) => {
        setLetraDos(e.target.value);
    };

    function handleDelete(index) {
        const newComplementos = complementos.filter((_, i) => i !== index);
        setComplementos(newComplementos);
    }

    function handleClickTraslate() {
        setClickCount(clickCount + 1);

        if (clickCount % 2 === 0) {
            setComplementos([...complementos, complemento]);
            setComplemento("");
            setShowInput(false);
        } else {
            setShowInput(true);
        }
    }



    const handleSufijoChange = (e) => {
        setSufijo(e.target.value);
    };

    const handleBisChange = (e) => {
        setBis(e.target.value);
    };

    const handleSufijoDosChange = (e) => {
        setSufijoDos(e.target.value);
    };

    const handleBisDosChange = (e) => {
        setBisDos(e.target.value);
    };



    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleOptionChangeActive = (e) => {
        setSelectedOptionActive(e.target.value);
    };

    const handleOptionChangePlan = (e) => {
        setSelectedOptionPlan(e.target.value);
    };

    const toggleVentana = () => {
        setMostrarVentana(!mostrarVentana);

    };

    const toggleVentanaPago = () => {
        setMostrarVentanaPago(!mostrarVentanaPago);

    };

    const toggleVentanaEdit = () => {
        setMostrarVentanaEdit(!mostrarVentanaEdit);

    };

    const toggleVentanaTitular = () => {
        setMostrarVentanaTitular(!mostrarVentanaTitular);

    };
    const toggleVentanaActive = () => {
        setMostrarVentanaActive(!mostrarVentanaActive);

    };
    const toggleVentanaReinicio = () => {
        setMostrarVentanaReinicio(!mostrarVentanaReinicio);

    };

    const handleTextoChange = (event) => {
        setTexto(event.target.value);
    };

    const handleClick = () => {
        setIsClicked(!isClicked);

    };

    if (!detail) {
        return (
            <div>
                {/* Mensaje o componente de fallback cuando no hay datos disponibles */}
                <p>No se encontró información para mostrar. Por favor, realiza una búsqueda.</p>
            </div>
        );
    }


    return (

        <>
            <div className="" >

                <div className="px-4 md:px-10 py-4 md:py-7 bg-pack-six bg-cover bg-center  rounded-tl-lg rounded-tr-lg">
                    <div className="sm:flex items-center justify-between">
                        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Informacion General</p>
                        <div>
                            <button onclick="popuphandler(true)" className="mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-blue-500 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/40 text-white font-semibold rounded-lg">
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    id="medio de pago"
                    className="block px-2.5 pb-2.5 pt-4 w-56 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                >

                    Id: {userLogin}
                </div>


                <div className="flex justify-center ">
                    <div className="max-w-screen w-full mt-10 px-10">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <div className="bg-white border-t-4 border-green-400 rounded-lg p-4 md:p-6 shadow-md ">
                                    <div className="flex justify-between items-center ">
                                        <img className="w-16 h-16" src={user} alt="User" />
                                        <h2 className="text-lg font-bold text-green-400">ACTIVO</h2>
                                    </div>
                                    <div className="flex items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Contrato</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black">COL_4001</h2>
                                    </div>
                                    <div className="flex  items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Titular</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base dark:text-gray-100 text-black">
                                            {`${detail?.primerNombre ?? ''} ${detail?.segundoNombre ?? ''} ${detail?.primerApellido ?? ''} ${detail?.segundoApellido ?? ''}`}
                                        </h2>

                                    </div>
                                    <div className="flex items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Niup</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black"> {detail?.documento}</h2>
                                    </div>
                                    <div className="flex items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Direccion</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black">{detail?.direccion}</h2>
                                    </div>


                                </div>
                            </div>

                            <div className="col-span-1">
                                <div className="bg-white border-t-4 border-cyan-400  rounded-lg p-4 md:p-6  shadow-md ">
                                    <div className="flex justify-between items-center ">
                                        <img className="w-16 h-16" src={contact} alt="Wifi" />
                                        <h2 className="text-lg font-bold text-cyan-400">ACTUALIZADO</h2>
                                    </div>
                                    <div className="flex items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Telefono de Contacto</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base text-black">{detail?.telefono} </h2>
                                    </div>
                                    <div className="flex items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Telefono Alternativo</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black">{detail?.telefonoAlterno}</h2>
                                    </div>
                                    <div className="flex w-full  items-center mt-4">
                                        <h2 className="text-base font-bold dark:text-gray-100 text-gray-900">Correo Electronico</h2>
                                    </div>

                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black">{detail?.email}</h2>
                                    </div>

                                    <div className="flex items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Barrio o Localidad</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black">
                                            {`${detail?.barrio ?? ''} ${detail?.ciudad ?? ''}`}
                                        </h2>
                                    </div>


                                </div>
                            </div>

                            <div className="col-span-1">
                                <div className="bg-white border-t-4 border-blue-400 rounded-lg p-4 md:p-6  shadow-md ">
                                    <div className="flex justify-between items-center ">
                                        <img className="w-16 h-16" src={factur} alt="User" />
                                        <h2 className="text-lg font-bold text-blue-400">$50.0000</h2>
                                    </div>
                                    <div className="flex justify-between items-center mt-6">
                                        <h2 className="text-base font-bold text-gray-900">Deuda</h2>

                                        <h2 className="text-base font-bold text-blue-400">$0</h2>
                                    </div>
                                    <div className="flex items-center mt-6">
                                        <h2 className="text-base font-bold text-gray-900">Mensualidad Pendiente</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base text-black">01/May/2023      -    30/May/2023</h2>
                                    </div>

                                    <div className="flex w-full  items-center mt-6">
                                        <h2 className="text-base font-bold dark:text-gray-100 text-gray-900">Plan</h2>
                                    </div>

                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black">
                                            {`${detail?.selectedOption  ?? '' } ${detail?.selectedOptionApp ?? ''}`}
                                        </h2>
                                    </div>
                                    <div className="flex items-center mt-4">
                                        <h2 className="text-base font-bold text-gray-900">Servicios Adicionales</h2>
                                    </div>
                                    <div>
                                        <h2 className="text-base  dark:text-gray-100 text-black">TV + Amazon Prime</h2>
                                    </div>



                                </div>
                            </div>




                        </div>
                    </div>
                </div>

            </div>

            <div>
                <div className='flex justify-center py-10'>
                    <button

                        onClick={toggleVentanaEdit}
                        className="mt-4 sm:mt-0 mr-6  inline-flex items-start justify-start px-6 py-3 bg-gradient-to-r from-lime-500 to-green-500 hover:bg-gradient-to-r hover:from-lime-500 hover:to-green-600 shadow-lg  hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                    >
                        <RiEditBoxLine className="w-5 h-5 ml-2" />
                        Actualizar Datos

                    </button>

                    {mostrarVentanaEdit && (
                        <div className="fixed inset-0 flex items-center justify-center">


                            <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                            <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-96 h-96">

                                <h3 className="text-lg font-bold mb-2 text-center">Confirmar Datos con el Usuario</h3>
                                <h3 className="text-base mb-2 text-center">Debe suministrar informacion verdadera que facilite su atencion</h3>
                                <div className="relative">
                                    <div className="absolute left-3 top-3 text-gray-400">
                                        <RiEditBoxLine size={18} />
                                    </div>
                                    <input
                                        className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                        placeholder="Telefono de contacto"
                                        value={texto}
                                        onChange={handleTextoChange}
                                    />
                                    <input
                                        className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                        placeholder="Telefono Alternativo"
                                        value={texto}
                                        onChange={handleTextoChange}
                                    />
                                    <input
                                        className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                        placeholder="Correo Electronico"
                                        value={texto}
                                        onChange={handleTextoChange}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                toggleVentanaEdit();
                                                toast.error('Pago cancelado');
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                handleClick();
                                                toast.success('Pago realizado');
                                            }}
                                        >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <button

                        onClick={toggleVentanaTitular}
                        className="bg-gradient-to-r from-cyan-500 to-sky-500 hover:bg-gradient-to-r hover:from-cyan-600 hover:to-sky-600 mt-4 sm:mt-0 mr-6  inline-flex items-start justify-start px-6 py-3  shadow-lg  hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                    >
                        <RiEmpathizeLine className="w-5 h-5 ml-2" />
                        Cambio de Titular

                    </button>

                    {mostrarVentanaTitular && (
                        <div className="fixed inset-0 flex items-center justify-center">


                            <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                            <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-96 h-96">

                                <h3 className="text-lg font-bold mb-2 text-center">Cambio de Titular </h3>
                                <h3 className="text-base mb-2 text-center">Recuerda validar informacion </h3>
                                <div className="relative">
                                    <div className="absolute left-3 top-3 text-gray-400">
                                        <RiEmpathizeLine size={18} />
                                    </div>
                                    <input
                                        className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                        placeholder="Ingrese Nombre"
                                        value={texto}
                                        onChange={handleTextoChange}
                                    />
                                    <div className="relative inline-flex w-full border rounded-lg p-2 mb-4 pl-1">
                                        <select
                                            className="appearance-none bg-white  hover:border-gray-500 px-4 py-2 pr-8   shadow leading-tight focus:outline-none focus:shadow-outline"
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                            <option value="">Tipo de Documento</option>
                                            <option value="nit">NIT</option>
                                            <option value="cedula">Cédula de ciudadanía</option>
                                            <option value="cedulaExtranjeria">Cédula de extranjería</option>
                                            <option value="permisoProteccionTemporal">Permiso de protección temporal</option>
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                className="fill-current h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M6 8l4 4 4-4H6z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <input
                                        className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                        placeholder="Documento de Identidad"
                                        value={texto}
                                        onChange={handleTextoChange}
                                    />

                                </div>
                                <div className="flex justify-between">
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                toggleVentanaTitular();
                                                toast.error('Pago cancelado');
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                handleClick();
                                                toast.success('Pago realizado');
                                            }}
                                        >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <button

                        onClick={toggleVentanaPago}
                        className="mt-4 sm:mt-0 mr-6  inline-flex items-start justify-start px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600  shadow-lg  hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                    >
                        <BsCurrencyDollar className="w-5 h-5 ml-2" />
                        Traslado Servicio

                    </button>

                    {mostrarVentanaPago && (
                        <div className="fixed inset-0 flex items-center justify-center">


                            <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                            <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-auto h-auto">

                                <h3 className="text-lg font-bold mb-2 text-center">Direccion Nueva</h3>
                                <h3 className="text-base mb-2 text-center">Confirma datos con el recibo publico</h3>

                                <div className="flex justify-between">

                                    <div className="relative w-1/4 md:w-64">
                                        <input
                                            id="documento"
                                            type="text"
                                            value={documento}
                                            onChange={handleDocumentChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            placeholder=" "
                                        />
                                        <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                            CC/NIT
                                        </label>
                                    </div>
                                    <div className="flex-1 pl-2 items-center text-center justify-center mr-2">
                                        <input
                                            type="text"
                                            value={direccion}
                                            onChange={(e) => setDireccion(e.target.value)}
                                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        />
                                        <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"></label>
                                    </div>

                                </div>



                                <div className="grid grid-cols-3 md:grid-cols-6 gap-4 lg:ml-10 mt-8">

                                    <div className="relative md:w-32 ml-10">
                                        <select
                                            required
                                            value={via}
                                            onChange={handleViaChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        >
                                            <option value="" disabled selected hidden>Via</option>
                                            <option value="Avenida">Avenida</option>
                                            <option value="Autopista">Autopista</option>
                                            <option value="Calle">Calle</option>
                                            <option value="Carrera">Carrera</option>
                                            <option value="Diagonal">Diagonal</option>
                                            <option value="Transversal">Transversal</option>
                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>

                                    </div>

                                    <div className="relative md:w-32  md:ml-10 md:mt-0 ">
                                        <input required type="number" value={numero} onChange={handleNumeroChange} className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Numero</label>
                                    </div>
                                    <div className="relative md:w-32 md:ml-10">
                                        <select

                                            value={letra}
                                            onChange={handleLetraChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        >
                                            <option value="" disabled selected hidden>Letra</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="E">E</option>
                                            <option value="F">F</option>
                                            <option value="G">G</option>
                                            <option value="H">H</option>
                                            <option value="I">I</option>
                                            <option value="J">J</option>
                                            <option value="K">K</option>
                                            <option value="L">L</option>
                                            <option value="M">M</option>
                                            <option value="N">N</option>
                                            <option value="O">O</option>
                                            <option value="P">P</option>
                                            <option value="Q">Q</option>
                                            <option value="R">R</option>
                                            <option value="S">S</option>
                                            <option value="T">T</option>
                                            <option value="U">U</option>
                                            <option value="V">V</option>
                                            <option value="W">W</option>
                                            <option value="X">X</option>
                                            <option value="Y">Y</option>
                                            <option value="Z">Z</option>


                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>

                                    </div>

                                    <div className="relative md:w-32 md:ml-10">
                                        <div className="flex items-center">
                                            <select

                                                value={sufijo}
                                                onChange={handleSufijoChange}
                                                className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            >
                                                <option value="" disabled selected hidden>Sufijo</option>
                                                <option value="Este">Este</option>
                                                <option value="Norte">Norte</option>
                                                <option value="Oriente">Oriente</option>
                                                <option value="Occidente">Occidente</option>
                                                <option value="Oeste">Oeste</option>
                                                <option value="Sur">Sur</option>
                                            </select>
                                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="relative md:w-32 md:ml-10">
                                        <select

                                            value={bis}
                                            onChange={handleBisChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        >
                                            <option value="" disabled selected hidden>Bis</option>
                                            <option value="Bis">Bis</option>

                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>


                                    </div>

                                    <div className="relative text-center items-center ml-10 md:w-20">
                                        <label className="absolute text-3xl text-blue-500 dark:text-gray-400 duration-300 ">#</label>
                                    </div>

                                </div>


                                <div className="grid grid-cols-3 md:grid-cols-6 gap-4 lg:ml-10 mt-8">

                                    <div className="relative md:w-32 ml-10">
                                        <input required type="number" value={numeroDos} onChange={handleNumeroDosChange} className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Numero</label>
                                    </div>
                                    <div className="relative md:w-32 md:ml-10">
                                        <select

                                            value={letraDos}
                                            onChange={handleLetraDosChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        >
                                            <option value="" disabled selected hidden>Letra</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="E">E</option>
                                            <option value="F">F</option>
                                            <option value="G">G</option>
                                            <option value="H">H</option>
                                            <option value="I">I</option>
                                            <option value="J">J</option>
                                            <option value="K">K</option>
                                            <option value="L">L</option>
                                            <option value="M">M</option>
                                            <option value="N">N</option>
                                            <option value="O">O</option>
                                            <option value="P">P</option>
                                            <option value="Q">Q</option>
                                            <option value="R">R</option>
                                            <option value="S">S</option>
                                            <option value="T">T</option>
                                            <option value="U">U</option>
                                            <option value="V">V</option>
                                            <option value="W">W</option>
                                            <option value="X">X</option>
                                            <option value="Y">Y</option>
                                            <option value="Z">Z</option>


                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>

                                    </div>
                                    <div className=" relative md:w-32 md:ml-10">
                                        <select

                                            value={sufijoDos}
                                            onChange={handleSufijoDosChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        >
                                            <option value="" disabled selected hidden>Sufijo</option>
                                            <option value="Este">Este</option>
                                            <option value="Norte">Norte</option>
                                            <option value="Oriente">Oriente</option>
                                            <option value="Occidente">Occidente</option>
                                            <option value="Oeste">Oeste</option>
                                            <option value="Sur">Sur</option>
                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>

                                    </div>




                                    <div className="relative md:w-32 md:ml-10">
                                        <select

                                            value={bisDos}
                                            onChange={handleBisDosChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        >
                                            <option value="" disabled selected hidden>Bis</option>
                                            <option value="Bis">Bis</option>

                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>


                                    </div>


                                    <div className="md:w-32  md:ml-10 ">
                                        <input required type="number" value={numeroTres} onChange={handleNumeroTresChange} className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label
                                            className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 
                                                       scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2
                                                       peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                                       peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75
                                                       peer-focus:-translate-y-4 left-1 ">Numero</label>
                                    </div>


                                </div>



                                <div className="flex lg:ml-10 mt-8 pb-4 ">
                                    <button
                                        className="text-gray-900  flex justify-between  bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-5 mb-2"
                                        onClick={handleClickTraslate}
                                    >
                                        <IoMdAdd className="inline-block mr-2" />
                                        Complemento
                                    </button>

                                    {showInput && (
                                        <div className="px-5">
                                            <input
                                                className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                type="text"
                                                value={complemento}
                                                onChange={(e) => setComplemento(e.target.value)}
                                            />
                                        </div>
                                    )}

                                    {complementos.map((comp, index) => (
                                        <div key={comp} className="px-5">
                                            <div className="flex items-center">
                                                <input
                                                    className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                    type="text"
                                                    value={comp}
                                                    readOnly
                                                />
                                                <button
                                                    className="ml-2 w-10 h-10 text-red-500 hover:text-red-700"
                                                    onClick={() => handleDelete(index)}
                                                >
                                                    <IoMdTrash />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div class="container flex justify-center w-full pb-4">

                                    <div
                                        {...getRootProps2()}
                                        className={`py-5 flex items-center text-center justify-center h-32 w-96  ${isDragActive2 ? 'border-blue-500' : 'border-gray-400'
                                            }`}
                                    >

                                        <label htmlFor="Recibo Publico" className="flex flex-col items-center justify-center w-96 h-32 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-blue-100 dark:border-blue-600 dark:hover:border-blue-500 dark:hover:bg-blue-600">
                                            {!fileRp ? (
                                                <div className="flex flex-col items-center justify-center ">
                                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Cargar Documentos</span> Recibo Publico</p>
                                                </div>
                                            ) : null}

                                            <input {...getInputProps2()} />
                                            {fileRp ? (
                                                <>
                                                    <div className="flex flex-col  py-10 items-center justify-center h-screen">
                                                        <div className=" py-10">
                                                            <p className="mt-10">Archivo seleccionado: {fileRp.name}</p>
                                                        </div>
                                                        {fileRp.type === 'application/pdf' ? (
                                                            <iframe src={URL.createObjectURL(fileRp)} title={fileRp.name} className="w-full h-32" />
                                                        ) : (
                                                            <img src={URL.createObjectURL(fileRp)} alt={fileRp.name} className="w-full h-32 object-contain" />
                                                        )}
                                                        <div className="py-8">
                                                            <svg
                                                                htmlFor="enviar el archivo"
                                                                onClick={handleUploadRp} aria-hidden="true" className="w-14 h-14 mb-8  text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="flex items-center ">
                                                    <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M14 18H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2zM6 4v12h8V4H6z" clipRule="evenodd" />
                                                    </svg>
                                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                </div>
                                            )}



                                        </label>
                                    </div>

                                </div>


                                <div className="flex justify-center space-x-10">
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                toggleVentanaPago();
                                                toast.error('Traslado cancelado');
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="mr-10">
                                        <button
                                            className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r  hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                handleClick();
                                                toast.success('Traslado Exitoso');
                                            }}
                                        >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>

            <div className="flex justify-center ">
                <div className="max-w-screen w-full mt-10 px-10">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <div className="bg-white border-t-4  border-y-purple-400 rounded-lg p-4 md:p-6 shadow-md ">
                                <img className="w-16 h-16" src={calendario} alt="User" />

                                <div className="flex items-center mt-4">
                                    <h2 className="text-base font-bold text-gray-900">Fecha de creacion</h2>
                                </div>
                                <div>
                                    <h2 className="text-base  dark:text-gray-100 text-black">01/enero/2023</h2>
                                </div>
                                <div className="flex  items-center mt-4">
                                    <h2 className="text-base font-bold text-gray-900">Fecha de Activacion</h2>
                                </div>
                                <div>
                                    <h2 className="text-base  dark:text-gray-100 text-black">05/enero/2023</h2>
                                </div>
                                <div className="flex items-center mt-4">
                                    <h2 className="text-base font-bold text-gray-900">Retiro</h2>
                                </div>
                                <div>
                                    <h2 className="text-base  dark:text-gray-100 text-black">00/00/0000</h2>
                                </div>


                            </div>
                        </div>

                        <div className="col-span-1">
                            <div className="bg-white border-t-4 border-orange-400 rounded-lg p-4 md:p-6  shadow-md ">
                                <div className="flex justify-between items-center ">
                                    <img className="w-16 h-16" src={wifi} alt="Wifi" />
                                    <h2 className="text-lg font-bold text-orange-400">ONLINE</h2>
                                </div>
                                <div className="flex items-center mt-4">
                                    <h2 className="text-base font-bold text-gray-900">IP</h2>
                                </div>
                                <div>
                                    <h2 className="text-base text-black">10.11.49.116</h2>
                                </div>
                                <div className="flex w-full  items-center mt-4">
                                    <h2 className="text-base font-bold dark:text-gray-100 text-gray-900">Velocidad Contratada</h2>
                                </div>

                                <div>
                                    <h2 className="text-base  dark:text-gray-100 text-black">INTERNET_FTTH_TV_100M</h2>
                                </div>

                                <div className="flex items-center mt-4">
                                    <h2 className="text-base font-bold text-gray-900">Key</h2>
                                </div>
                                <div>
                                    <h2 className="text-base  dark:text-gray-100 text-black">307376_3C846A40A7DB</h2>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>








            <div>

                <div className='flex justify-center py-10'>


                    <button

                        onClick={toggleVentanaActive}
                        className="mt-4 sm:mt-0 mr-6 bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 inline-flex items-start justify-start px-6 py-3  shadow-lg  hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                    >
                        <RiLightbulbFlashLine className="w-5 h-5 ml-2" />
                        Activar Servicio

                    </button>

                    {mostrarVentanaActive && (
                        <div className="fixed inset-0 flex items-center justify-center">


                            <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                            <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-96 h-96">

                                <h3 className="text-lg font-bold mb-2 text-center">Reconexion de Servicio</h3>
                                <h3 className="text-base mb-2 text-center">Confirma los datos del equipo</h3>
                                <div className="relative">

                                    <div className="relative inline-flex w-full border rounded-lg p-2 mb-4 pl-1">
                                        <select
                                            className="appearance-none bg-white  hover:border-gray-500 px-4 py-2 pr-8   shadow leading-tight focus:outline-none focus:shadow-outline"
                                            value={selectedOptionActive}
                                            onChange={handleOptionChangeActive}
                                        >

                                            <option value="">Servicios a Reconectar</option>
                                            <option value="Internet">Internet</option>
                                            <option value="television">Television</option>
                                            <option value="Internet y television">Internet y television</option>
                                            <option value="Plataformas Streaming">Plataformas Streaming</option>


                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                className="fill-current h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M6 8l4 4 4-4H6z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="relative inline-flex w-full border rounded-lg p-2 mb-4 pl-1">
                                        <select
                                            className="appearance-none bg-white  hover:border-gray-500 px-4 py-2 pr-8   shadow leading-tight focus:outline-none focus:shadow-outline"
                                            value={selectedOptionPlan}
                                            onChange={handleOptionChangePlan}
                                        >


                                            <option value="">Selecciona el Plan</option>
                                            <option value="SOLO_20MG_FTTH_$42.000">SOLO_20MG_FTTH_$42.000</option>
                                            <option value="FTTH_20MG__TV_$59.000<">FTTH_20MG__TV_$59.000</option>
                                            <option value="SOLO_50MG_FTTH_$52.000">SOLO_50MG_FTTH_$52.000</option>
                                            <option value="FTTH_50MG__TV_$69.000">FTTH_50MG__TV_$69.000</option>

                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                                className="fill-current h-4 w-4"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M6 8l4 4 4-4H6z"
                                                />
                                            </svg>
                                        </div>
                                    </div>

                                    <input
                                        className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                        placeholder="Ingresa Key"
                                        value={texto}
                                        onChange={handleTextoChange}
                                    />

                                </div>
                                <div className="flex justify-between">
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                toggleVentanaActive();
                                                toast.error('Reconexion cancelada');
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                handleClick();
                                                toast.success('Pago realizado');
                                            }}
                                        >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <button

                        onClick={toggleVentanaReinicio}
                        className="mt-4 sm:mt-0 mr-6  inline-flex items-start justify-start px-6 py-3 bg-gradient-to-r from-orange-500 to-amber-500 hover:bg-gradient-to-r hover:from-orange-600 hover:to-amber-600 shadow-lg  hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                    >
                        <RiRefreshLine className="w-5 h-5 ml-2" />
                        Reiniciar Equipo

                    </button>

                    {mostrarVentanaReinicio && (
                        <div className="fixed inset-0 flex items-center justify-center">
                            <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                            <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-96 h-96">
                                <div className="flex items-center justify-center">
                                    <RiAlertLine className="w-10 h-10" />
                                </div>
                                <h3 className="text-lg font-bold mb-2 text-center">Reinicio Modem</h3>
                                <h3 className="text-base mb-2 text-center">¿Seguro quieres Reiniciar?</h3>
                                <div className="relative"></div>
                                <div className="flex justify-between">
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                toggleVentanaReinicio();
                                                toast.error('Reinicio cancelado');
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {
                                                handleClick();
                                                toast.success('Reinicio Exitoso');
                                            }}
                                        >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}



                </div>
                <ToastContainer />
            </div>



        </>









    )
}



