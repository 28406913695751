import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import Colnet from "../../assets/colnet.png";
import Factura from "../../assets/ic_factura.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDatabase, ref, query, orderByChild, equalTo, onValue } from 'firebase/database';
import { Link, useNavigate } from 'react-router-dom';
import fibra from '../../assets/fibra.png';

const Search = ({ onSearchResults }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const navigate = useNavigate();

  const handleClick = (result) => {
    navigate('/home', { state: { detail: result } });
  };

  const handleClickFactura = (result) => {
    navigate('/facturacion', { state: { detail: result } });
  };

  const handleSearch = () => {
    console.log("Iniciando búsqueda con searchTerm:", searchTerm); // Verifica que la función se inicia y muestra el término de búsqueda

    const db = getDatabase();
    const dbRef = ref(db, 'usuarios'); // Asegúrate de que 'usuarios' sea el nombre correcto del nodo
    const searchQuery = query(dbRef, orderByChild('documento'), equalTo(searchTerm));

    console.log("Ejecutando consulta en Firebase con searchTerm:", searchTerm); // Verifica que la consulta se va a ejecutar con el término de búsqueda correcto

    onValue(searchQuery, (snapshot) => {
      const data = snapshot.val();
      console.log("Datos recibidos de Firebase:", data); // Muestra los datos recibidos de Firebase

      if (data) {
        const results = Object.values(data);
        console.log("Resultados procesados:", results); // Muestra los resultados después de procesar los datos recibidos

        setSearchResults(results);
        onSearchResults(results);
        setShowResults(true);
      } else {
        console.log("No se encontraron resultados para:", searchTerm); // Indica si no se encontraron resultados
        toast.error('No se encontraron resultados. Intenta nuevamente.');
      }
    }, (error) => {
      console.error("Error al obtener datos de Firebase:", error); // Captura y muestra errores de la consulta a Firebase
    });
  };

  const handleInputClick = () => {
    setClicked(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleInputClick();
    handleSearch();
  };

  return (
    <div>
      <ToastContainer />
      {!showResults && (
        <div className="justify-center py-20 px-96">
          <img className={`w-96 h-72 ${clicked ? 'hidden' : ''}`} src={Colnet} alt="Logo" />
        </div>
      )}
      <div className="justify-center py-1 px-40 mt-10">
        <div className="w-full h-full hidden lg:flex text-center justify-center items-center pr-1">
          <div className="relative flex">
            {clicked && (
              <img
                className="w-16 h-16 mr-6"
                src={Colnet}
                alt="Logo"
              />
            )}
          </div>
          <div className="relative w-full">
            <form onSubmit={handleSubmit}>
              <label htmlFor="NIT/CC" className="mb-2 rounded-2xl text-sm font-medium text-gray-900 sr-only dark:text-white">
                NIT/CC
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <FaSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                </div>
                <input
                  type="search"
                  id="search"
                  className="block w-full p-4 pl-10 text-sm text-gray-900 border border-blue-300 rounded-3xl bg-white dark:text-white"
                  placeholder="NIT/CC"
                  value={searchTerm}
                  onChange={handleChange}
                  required
                />
                <button
                  type="submit"
                  className="text-blue-500 hover:text-white absolute right-2.5 bottom-2.5 rounded-xl bg-white hover:bg-blue-500 focus:outline-none font-medium text-sm px-4 py-2 dark:hover:bg-blue-700"
                >
                  Buscar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showResults && (
        <div className="min-h-screen mt-16 px-4 overflow-x-auto">
          <table className="w-full border border-collapse">
            <thead>
              <tr>
                <th className="border p-2">Documento</th>
                <th className="border p-2">Detalle</th>
                <th className="border p-2">Contrato</th>
                <th className="border p-2">Titular</th>
                <th className="border p-2">Dirección</th>
                <th className="border p-2">Plan de Servicios</th>
                <th className="border p-2">Estado</th>
                <th className="border p-2">Factura</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((result, index) => (
                <tr key={index}>
                  <td className="border p-2 transition duration-300 ease-in-out transform hover:underline hover:text-blue-500 cursor-pointer text-center">
                    <div onClick={() => handleClick(result)}>
                      {result.documento}
                    </div>
                  </td>
                  <td className="border p-2 text-center">{result.uuid}</td>
                  <td className="border p-2 text-center">{result.contrato}</td>
                  <td className="border p-2 text-center">{`${result.primerNombre} ${result.segundoNombre} ${result.primerApellido} ${result.segundoApellido}`}</td>
                  <td className="border p-2 text-center">{result.direccion}</td>
                  <td className="border p-2 text-center">{`${result.selectedOption} ${result.selectedOptionApp}`}</td>
                  <td className="border p-2 text-center">{result.estado}</td>
                  <td className="border p-2 text-center">
                    <img src={Factura} onClick={() => handleClickFactura(result)} className="h-16 w-16 items-center cursor-pointer" alt="Factura" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Search;
