import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { FiPlus } from "react-icons/fi"
import { toast, ToastContainer } from 'react-toastify';
import { BsCurrencyDollar } from 'react-icons/bs';
import { BiEraser } from 'react-icons/bi';
import { getDatabase, ref, query, orderByChild, equalTo, onValue, orderByKey } from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';
import user from "../../assets/user.png"
import factur from "../../assets/factur.png"
import contact from "../../assets/contact.png"

export default function Facturacion(props, { onSearchResults }) {


    const userLogin = props.user;
    console.log(userLogin);

    const constanteRecibida = props.constante;

    const [show, setShow] = useState(null)

    const [isClicked, setIsClicked] = useState(false);

    const [mostrarVentana, setMostrarVentana] = useState(false);
    const [mostrarVentanaPago, setMostrarVentanaPago] = useState(false);
    const [texto, setTexto] = useState('');
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [clicked, setClicked] = useState(false);
    const location = useLocation();
    const detail = location.state ? location.state.detail : null;


    console.log(userLogin);
    console.log(detail);







    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedItems(/* Add your item data here */);
        } else {
            setSelectedItems([]);
        }
    };

    const toggleDetails = (index) => {
        if (show === index) {
            setShow(null);  // Si ya está mostrando los detalles, ocultarlos
        } else {
            setShow(index); // Mostrar los detalles del índice específico
        }
    };

    const toggleItemSelection = (item) => {

        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };



    const toggleVentana = () => {
        setMostrarVentana(!mostrarVentana);

    };

    const toggleVentanaPago = () => {
        setMostrarVentanaPago(!mostrarVentanaPago);

    };

    const handleClick = () => {
        setIsClicked(!isClicked);

    };

    const handleClickFactura = () => {
        navigate('/edit', { state: { detail } });
    };

    const handleClickCnt = () => {
        navigate('/cnt', { state: { detail } });
    };

    const handleTextoChange = (event) => {
        setTexto(event.target.value);
    };




    const handleSearch = () => {
        console.log("Iniciando búsqueda con documento:", detail?.documento);

        const db = getDatabase();
        const nodosFacturas = ['acuerdoPago', 'pendientes', 'anuladas', 'pagadas']; // Lista de subnodos bajo 'facturas'

        // Creamos un array para almacenar las promesas de cada consulta
        const promises = [];

        // Iteramos sobre los subnodos de 'facturas'
        nodosFacturas.forEach(nodo => {
            const dbRef = ref(db, `facturas/${nodo}`);
            const nodoQuery = query(dbRef, orderByKey(), equalTo(detail?.documento));

            // Ejecutamos la consulta y guardamos la promesa en el array
            const promise = new Promise((resolve, reject) => {
                onValue(nodoQuery, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        resolve(Object.values(data)); // Resolvemos con los valores de los datos encontrados
                    } else {
                        resolve([]); // Si no hay datos, resolvemos con un array vacío
                    }
                }, (error) => {
                    reject(error); // Rechazamos la promesa en caso de error
                });
            });

            promises.push(promise); // Añadimos la promesa al array de promesas
        });

        // Ejecutamos todas las promesas de consulta
        Promise.all(promises)
            .then(results => {
                // Unificamos todos los resultados en un solo array
                const mergedResults = results.flat();
                console.log("Resultados procesados:", mergedResults);

                setSearchResults(mergedResults);
                setShowResults(true);
            })
            .catch(error => {
                console.error("Error al obtener datos de Firebase:", error);
                toast.error('Error al obtener resultados. Intenta nuevamente.');
            });
    };




    return (
        <>
            <div className="w-full sm:px-6">
                <ToastContainer />
                <div className="px-4 md:px-10 py-4 md:py-7 bg-pack-six bg-cover bg-center rounded-tl-lg rounded-tr-lg">
                    <div className="sm:flex items-center justify-between">
                        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Factura</p>
                        <div className="flex justify-end">
                            <div>
                                <button
                                    onClick={handleClickFactura}
                                    className="mt-4  mr-6 sm:mt-0 inline-flex items-start justify-start text-center px-6 py-3 bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                                >
                                    <FiPlus className="w-5 h-5 ml-2 justify-items-center" />
                                    <h2 className="text-base text-center items-center font-medium leading-none text-white">Agregar Factura</h2>

                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={toggleVentana}
                                    className="mt-4 sm:mt-0 mr-6  inline-flex items-center justify-center text-center px-6 py-3 bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600  shadow-lg hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                                >
                                    <BiEraser className="w-5 h-5 ml-2" />
                                    Nota Credito

                                </button>
                                {mostrarVentana && (
                                    <div className="fixed inset-0 flex items-center justify-center">

                                        <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                                        <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-96 h-72">

                                            <h3 className="text-lg font-bold mb-2 text-center">Nota Credito</h3>
                                            <h3 className="text-base mb-2 text-center">Selecciona factura</h3>
                                            <div className="relative">
                                                <div className="absolute left-3 top-3 text-gray-400">
                                                    <BsCurrencyDollar size={18} />
                                                </div>
                                                <input
                                                    className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                                    placeholder="Ingrese valor "
                                                    value={texto}
                                                    onChange={handleTextoChange}
                                                />
                                            </div>
                                            <div className="flex justify-between">
                                                <div className="">
                                                    <button
                                                        className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                                        onClick={() => {
                                                            toggleVentana();
                                                            toast.error('Recuerda abrir caja para facturar', { position: toast.POSITION.BOTTOM_RIGHT });
                                                        }}
                                                    >
                                                        Cancelar
                                                    </button>
                                                </div>
                                                <div className="">
                                                    <button
                                                        className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                                        onClick={() => {
                                                            handleClick();
                                                            toast.success('Base actualizada', { position: toast.POSITION.BOTTOM_RIGHT });
                                                        }}
                                                    >
                                                        Aceptar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <button

                                    onClick={handleClickCnt}
                                    className="mt-4 sm:mt-0 mr-6  inline-flex items-start justify-start px-6 py-3 bg-gradient-to-r from-lime-500 to-green-500 hover:bg-gradient-to-r hover:from-lime-500 hover:to-green-600 shadow-lg  hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                                >
                                    <BsCurrencyDollar className="w-5 h-5 ml-2" />
                                    Pagar Factura
                                    
                                    

                                </button>

                               
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ml-1 mb-14 mt-10 flex justify-around w-full h-auto">
                    <div className="bg-white border-t-4 border-green-400 rounded-lg p-4 md:p-6 shadow-md w-96">
                        <div className="flex justify-between items-center">
                            <img className="w-16 h-16" src={user} alt="User" />
                            <h2 className="text-lg font-bold text-green-400">ACTIVO</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Contrato</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">COL_4001</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Titular</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">
                                {`${detail?.primerNombre ?? ''} ${detail?.segundoNombre ?? ''} ${detail?.primerApellido ?? ''} ${detail?.segundoApellido ?? ''}`}
                            </h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Niup</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">{detail?.documento}</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Direccion</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">{detail?.direccion}</h2>
                        </div>
                    </div>

                    <div className="bg-white border-t-4 border-cyan-400 rounded-lg p-4 md:p-6 shadow-md w-96">
                        <div className="flex justify-between items-center">
                            <img className="w-16 h-16" src={contact} alt="Wifi" />
                            <h2 className="text-lg font-bold text-cyan-400">ACTUALIZADO</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Telefono de Contacto</h2>
                        </div>
                        <div>
                            <h2 className="text-base text-black">{detail?.telefono}</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Telefono Alternativo</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">{detail?.telefonoAlterno}</h2>
                        </div>
                        <div className="flex w-full items-center mt-4">
                            <h2 className="text-base font-bold dark:text-gray-100 text-gray-900">Correo Electronico</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">{detail?.email}</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Barrio o Localidad</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">
                                {`${detail?.barrio ?? ''} ${detail?.ciudad ?? ''}`}
                            </h2>
                        </div>
                    </div>

                    <div className="bg-white border-t-4 border-blue-400 rounded-lg p-4 md:p-6 shadow-md w-96">
                        <div className="flex justify-between items-center">
                            <img className="w-16 h-16" src={factur} alt="User" />
                            <h2 className="text-lg font-bold text-blue-400">$50.0000</h2>
                        </div>
                        <div className="flex justify-between items-center mt-6">
                            <h2 className="text-base font-bold text-gray-900">Deuda</h2>
                            <h2 className="text-base font-bold text-blue-400">$0</h2>
                        </div>
                        <div className="flex items-center mt-6">
                            <h2 className="text-base font-bold text-gray-900">Mensualidad Pendiente</h2>
                        </div>
                        <div>
                            <h2 className="text-base text-black">01/May/2023 - 30/May/2023</h2>
                        </div>
                        <div className="flex w-full items-center mt-6">
                            <h2 className="text-base font-bold dark:text-gray-100 text-gray-900">Plan</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">
                                {`${detail?.selectedOption ?? ''} ${detail?.selectedOptionApp ?? ''}`}
                            </h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Servicios Adicionales</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">TV + Amazon Prime</h2>
                        </div>
                    </div>
                </div>

                <div className="justify-end">
                    <button
                        className="bg-gradient-to-r from-orange-400 to-orange-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                        onClick={() => {
                            handleSearch();

                        }}
                    >
                        Actualizar
                    </button>
                </div>


                <div className="bg-white border-2 border-yellow-500/40 shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                    <table className="w-full whitespace-nowrap">
                        <thead>
                            <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                <th className="font-normal text-center pl-1">Codigo</th>
                                <th className="font-normal text-center pl-12">Creada</th>
                                <th className="font-normal text-center pl-12">Finaliza</th>
                                <th className="font-normal text-center pl-12">Estado</th>
                                <th className="font-normal text-center pl-20">Plan</th>
                                <th className="font-normal text-center pl-16">Medio de Pago</th>
                                <th className="font-normal text-center pl-16">Usuario</th>
                                <th className="font-normal text-center pl-16">Total</th>
                                {/* Agrega más columnas según los datos que desees mostrar */}
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.map((factura, index) => (
                                Object.keys(factura).map((key) => (
                                    <tr key={key} className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-yellow-400/60 border-b border-t border-gray-300">
                                        <td className="pl-4">{factura[key].codigo}</td>
                                        <td className="pl-12">{factura[key].fechaFacturacion} </td>
                                        <td className="pl-12">{factura[key].fechaLimitePago} </td>
                                        <td className="pl-12">{factura[key].estado}</td>
                                        <td className="pl-20">{factura[key].plan}</td>
                                        <td className="pl-16">{factura[key].medio_de_pago}</td>
                                        <td className="pl-16">{factura[key].usuario}</td>
                                        <td className="pl-16">{factura[key].total}</td>
                                        {/* Agrega más celdas según los campos que desees mostrar */}
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </table>
                </div>


            </div>







        </>
    );
}
