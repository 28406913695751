import React, { useState } from 'react'

import Sidebar from '../common/Sidebar';
import Navbar from '../common/Navbar';
import Home from './Home.jsx';
import Footer from '../common/Footer';




export default function Home2(props) {
  const user = props.user;
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="flex-none">
        <Sidebar />
      </div>
      <div className="flex-1 ml-72">
        <Navbar />
        <div className="container mx-auto py-2 px-4">
          <div>
            <Home user={user}/>
          </div>
        </div>
      </div>
      <div className="flex-none">
        <Footer />
      </div>
    </div>
  );
}
