import React, { useState } from 'react';
import Grafica from '../caja/Grafica';
import GraficaMes from '../caja/GraficaMes';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import TableTransaction from "../caja/TableTransaction"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiSave, FiX, FiPrinter, FiShoppingCart, FiPlus } from "react-icons/fi";
import { BsCurrencyDollar } from 'react-icons/bs';

export default function (props) {

  const userLogin = props.user;
  console.log(userLogin);

  const constanteRecibida = props.constante;


  const [isClicked, setIsClicked] = useState(false);

  const [mostrarVentana, setMostrarVentana] = useState(false);
  const [texto, setTexto] = useState('');



  const toggleVentana = () => {
    setMostrarVentana(!mostrarVentana);

  };

  const handleClick = () => {
    setIsClicked(!isClicked);

  };

  const handleTextoChange = (event) => {
    setTexto(event.target.value);
  };




  return (

    <>
      <ToastContainer position="top-right" />
      <div className={`filter  ${mostrarVentana ? 'blurred' : ''}`}>


        <div className="px-4 md:px-10 py-4 md:py-7 bg-pack-six bg-cover bg-center rounded-tl-lg rounded-tr-lg">
          <div className="sm:flex items-center justify-between">
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Caja</p>
            <div>
              <button
                className={`flex justify-between bg-gradient-to-r ${mostrarVentana ? 'from-red-500 to-yellow-500 hover:from-red-600 hover:to-yellow-600' : 'from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600'
                  } text-white font-bold py-2 px-4 rounded ml-4`}
                onClick={toggleVentana}
              >
                <FiShoppingCart className='w-5 h-5 mr-2' />
                {mostrarVentana ? 'Cerrar Caja' : 'Abrir Caja'}
              </button>
              {mostrarVentana && (
                <div className="fixed inset-0 flex items-center justify-center">

                  <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                  <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-96 h-72">

                    <h3 className="text-lg font-bold mb-2 text-center">Base Caja</h3>
                    <h3 className="text-base mb-2 text-center">Saldo Inicial</h3>
                    <div className="relative">
                      <div className="absolute left-3 top-3 text-gray-400">
                        <BsCurrencyDollar size={18} />
                      </div>
                      <input
                        className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                        placeholder="Ingrese valor "
                        value={texto}
                        onChange={handleTextoChange}
                      />
                    </div>
                    <div className="flex justify-between">
                      <div className="">
                        <button
                          className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                          onClick={() => {
                            toggleVentana();
                            toast.error('Recuerda abrir caja para facturar');
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                      <div className="">
                        <button
                          className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                          onClick={() => {
                            handleClick();
                            toast.success('Base actualizada');
                          }}
                        >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          id="medio de pago"
          className="block px-2.5 pb-2.5 pt-4 w-56 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
        >

          Id:{userLogin}
        </div>
        <div className="flex justify-between">
          <div className=" mx-auto mt-8 flex flex-col items-center">
            <h1 className="text-2xl font-bold mb-4">Recaudo Semanal</h1>
            <Grafica  />
          </div>
          <div className="mx-auto mt-8 flex flex-col items-center">
            <h1 className="text-2xl font-bold mb-4">Recaudo Mensual</h1>
            <GraficaMes  />
          </div>
        </div>
        <div className="flex justify-center py-10">
          <button className="flex justify-between bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white font-bold py-2 px-4 rounded">
            <FiPrinter className='w-5 h-5 mr-2' />
            Imprimir
          </button>
          <button className="flex justify-between bg-gradient-to-r from-red-500 to-yellow-500 hover:from-red-600 hover:to-yellow-600 text-white font-bold py-2 px-4 rounded ml-4">
            <FiX className='w-5 h-5 mr-2' />
            Cerrar caja
          </button>





        </div>



        <div className="flex">
          <TableTransaction />
        </div>
      </div>
    </>
  );

}



