import React, { useState } from 'react'
import Orden from './Orden';
import Sidebar from '../common/Sidebar';
import Navbar from '../common/Navbar';



export default function Ordenes2(props) {
    const user = props.user;

    return (
        <>
            <div className="w-full h-full bg-white">        
                <div className="flex flex-no-wrap">
                    <Sidebar/>
                    <div className="w-full" style={{marginLeft:"288px"}}>
                        <Navbar />           
                        <div className="mx-auto py-2 w-full px-2">
                            <div>
                                <Orden user={user}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}