import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { db } from "../../firebase";
import { getDatabase, ref, set } from "firebase/database";
import { FiSave, FiX } from "react-icons/fi"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DatePickerComponent from './Datepicker1';

import { RiGpsLine, RiScanLine } from "react-icons/ri";
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from './TimePicker';





export default function Orden(props) {

  const userLogin = props.user;
  console.log(userLogin);

  const constanteRecibida = props.constante;




  const [activeIcons, setActiveIcons] = useState({
    tag: false,
    bell: false,
    calendar: false,
    user: false,
    archive: false,

  });

  const agregarValorABell = (e) => {
    guardarDatos(e);
    setActiveIcons((prevActiveIcons) => {
      const newActiveIcons = {
        ...prevActiveIcons,
        bell: !prevActiveIcons.bell,
      };
      if (newActiveIcons.bell) {
        setValorInicial((prevValorInicial) => ({
          ...prevValorInicial,
          opcion: "prioritario",
        }));
      } else {
        setValorInicial((prevValorInicial) => {
          const { opcion, ...updatedValorInicial } = prevValorInicial;
          return updatedValorInicial;
        });
      }
      return newActiveIcons;
    });
  };

  const handleIconClick = (icon) => {
    setActiveIcons((prevActiveIcons) => {
      const newActiveIcons = {
        ...prevActiveIcons,
        [icon]: !prevActiveIcons[icon],
      };

      if (icon === 'bell') {
        setValorInicial((prevValorInicial) => {
          if (newActiveIcons.bell) {
            return {
              ...prevValorInicial,
              opcion: "Prioridad 1",
            };
          } else {
            const { opcion, ...updatedValorInicial } = prevValorInicial;
            return updatedValorInicial;
          }
        });
      }

      return newActiveIcons;
    });
  };


  const handleIconFocus = (icon) => {
    setActiveIcons((prevActiveIcons) => ({
      ...prevActiveIcons,
      [icon]: true,
    }));
  };



  const Estado = [
    { label: "Creado", value: "Creado" },
    { label: "Asignado", value: "Asignado" },
    { label: "En Proceso", value: "En Proceso" },
    { label: "Finalizado", value: "Finalizado" }
  ];

  const solution = [
    { label: "Solucion PC", value: "Solucion PC" },
    { label: "Visita Tecnica", value: "Visita Tecnica" },
    { label: "Escalamiento", value: "Escalamiento" },
    { label: "Informacion", value: "Informacion" }
  ];

  const asesores = [
    { label: "Nobey Vanegas", value: "Nobey Vanegas" },
    { label: "Jonathan Vanegas", value: "Jonathan Vanegas" },
    { label: "Yimer Vanegas", value: "Yimer Vanegas" }
  ];

  const falla = [
    { label: "SIN @", value: "SIN @" },
    { label: "Lentitud", value: "Lentitud" },
    { label: "Intermitencia", value: "Intermitencia" },
    { label: "TV sin señal", value: "TV sin señal" },
    { label: "Informacion", value: "Informacion" },
    { label: "Señal Deficiente", value: "Señal Deficiente" }
  ];



  const [valorInicial, setValorInicial] = useState({
    fechaCreacion: "",
    horaCreacion: "",
    fechaAgendamiento: "",
    horaAgendamiento: "",
    pqr: "",
    tipo: "",
    asesores: "",
    solution: "",
    codigo: "",
    documento: "",
    nombres: "",
    apellidos: "",
    zona: "",
    barrio: "",
    estado: "",
    falla: "",
    comentarios: ""

  });


  const [userZona, setUserZona] = useState("");
  const [userEstado, setUserEstado] = useState("");
  const [userFalla, setUserFalla] = useState("");
  const [userTecnico, setUserTecnico] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [chatChecked, setChatChecked] = useState(false);
  const [llamadaChecked, setLlamadaChecked] = useState(false);

  const handleChatChange = (event) => {
    setChatChecked(event.target.checked);
    if (event.target.checked) {
      setLlamadaChecked(false);
      setValorInicial((prevState) => ({
        ...prevState,
        tipo: 'Chat'
      }));
    } else {
      setValorInicial((prevState) => ({
        ...prevState,
        tipo: ''
      }));
    }
  };

  const handleLlamadaChange = (event) => {
    setLlamadaChecked(event.target.checked);
    if (event.target.checked) {
      setChatChecked(false);
      setValorInicial((prevState) => ({
        ...prevState,
        tipo: 'Llamada'
      }));
    } else {
      setValorInicial((prevState) => ({
        ...prevState,
        tipo: ''
      }));
    }
  };

  const captureInputs = (e) => {
    const { id, value } = e.target;
    setUser((prevState) => ({ ...prevState, [id]: value }));
    console.log(valorInicial);
    generarConsecutivo();


    setValorInicial((prevState) => ({ ...prevState, ...user }));
  };





  const guardarDatos = async (e) => {
    e.preventDefault();

    try {
      const nodo = `ordenes/creadas/${valorInicial.documento}`;
      const nodoA = `ordenes/asignadas/${valorInicial.documento}`; // Ruta del nodo donde se guardarán los datos
      const nodoE = `ordenes/enProceso/${valorInicial.documento}`;
      const nodoC = `ordenes/cerradas/${valorInicial.documento}`;

      await set(ref(getDatabase(), nodoE), valorInicial);

      setValorInicial({
        fechaCreacion: "",
        horaCreacion: "",
        fechaAgendamiento: "",
        horaAgendamiento: "",
        pqr: "",
        tipo: "",
        asesores: "",
        solution: "",
        codigo: "",
        documento: "",
        nombres: "",
        apellidos: "",
        zona: "",
        barrio: "",
        estado: "",
        falla: "",
        comentarios: ""
      });

      toast.success("Orden creada");

      // Reiniciar los valores del formulario
    } catch (error) {
      console.log(error);
      toast.error("Error en orden: " + error);
    }
  };



  const handleSelectEstado = ({ value }) => {
    console.log(value);
    setUserEstado(value);
    setUser((prevState) => ({
      ...prevState,
      estado: value
    }));


  };

  const handleSelectZona = ({ value }) => {
    console.log(value);
    setUserZona(value);
    setUser((prevState) => ({
      ...prevState,
      solution: value
    }));
  };



  const handleSelectFalla = ({ value }) => {
    console.log(value);
    setUserFalla(value);
    setUser((prevState) => ({
      ...prevState,
      falla: value
    }));
  };

  const handleSelectTecnico = ({ value }) => {
    console.log(value);
    setUserTecnico(value);
    setUser((prevState) => ({
      ...prevState,
      asesores: value
    }));
  };


  const capturarFechaCreacion = () => {
    const date = new Date();

    const opcionesFecha = { year: "numeric", month: "long", day: "numeric" };
    const opcionesHora = { hour: "numeric", minute: "numeric", hour12: true };

    const fechaCreacion = date.toLocaleString(undefined, opcionesFecha);
    const horaCreacion = date.toLocaleString(undefined, opcionesHora);

    setValorInicial((prevState) => ({
      ...prevState,
      fechaCreacion,
      horaCreacion
    }));


  };

  const [user, setUser] = useState({});

  const capturarFechaAgendamiento = (date) => {
    setSelectedDate(date);
    console.log(date);
    setValorInicial((prevState) => ({
      ...prevState,
      fechaAgendamiento: date


    }
    ));

  };

  const capturarHoraAgendamiento = (time) => {
    setSelectedTime(time);
    console.log(time);
    setValorInicial((prevState) => ({
      ...prevState,
      horaAgendamiento: time
    }));
  };


  let consecutivo = 1000;

  // Función para generar el número consecutivo de 6 dígitos
  function generarConsecutivo() {
    consecutivo++; // Incrementar el valor del consecutivo
    if (consecutivo > 999999) {
      consecutivo = 1000; // Reiniciar el consecutivo si supera los 6 dígitos
    }

    // Formatear el número con ceros a la izquierda para tener siempre 6 dígitos
    const numeroFormateado = String(consecutivo).padStart(6, '0');

    // Actualizar el elemento <h1> con el nuevo número consecutivo
    const h1Element = document.getElementById('pqr');
    h1Element.textContent = numeroFormateado;

    setUser((prevState) => ({
      ...prevState,
      pqr: numeroFormateado
    }));
  }








  return (

    <div>
      <ToastContainer />
      <div id="popup" className="inset-0 z-50 right-100  py-12">
        <div className="flex w-full justify-center ">
          <div className="bg-white dark:bg-gray-800 shadow-xl rounded-2xl  shadow-blue-500/50 hover:shadow-blue-500/40 w-full md:w-11/12 pt-10 px-10 max-w-2xl z-50">
            <div className="container flex flex-col w-full h-auto justify-between">
              <form onSubmit={agregarValorABell}>
                <div className="flex w-full justify-between items-center">
                  <h2 className="text-4xl font-bold dark:text-gray-100 text-gray-900">Orden de Trabajo</h2>
                  <h1 onChange={captureInputs} value={user.pqr} id="pqr" className='text-4xl text-red-600'>1000</h1>


                </div>

                <div className="grid grid-cols-2 py-10 gap-2 items-center justify-between">
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={chatChecked}
                        onChange={handleChatChange}
                        className="mr-3"
                      />
                      Chat
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={llamadaChecked}
                        onChange={handleLlamadaChange}
                        className="mr-3"
                      />
                      Llamada
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-2 py-4 gap-2 items-center justify-between">


                  <div>
                    <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                      Fecha
                    </label>
                    <h2 className='text-center text-base from-indigo-950 mt-3 rounded-full bg-blue-200 py-2 px-4 w-64 h-10  '>{valorInicial.fechaCreacion}</h2>

                  </div>
                  <div>
                    <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                      Hora
                    </label>
                    <h2 className='text-center text-base from-indigo-950 mt-3 rounded-full bg-blue-200 py-2 px-4 w-64 h-10'>{valorInicial.horaCreacion}</h2>

                  </div>



                </div>



                <div className="grid grid-cols-2 py-4 gap-2 items-center justify-between">
                  <div>
                    <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                      Fecha de Agendamiento
                    </label>
                    <DatePickerComponent selected={selectedDate} onChange={capturarFechaAgendamiento} />
                  </div>

                  <div>
                    <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                      Hora de Agendamiento
                    </label>
                    <TimePicker selected={selectedTime} onChange={capturarHoraAgendamiento} />

                  </div>

                </div>

                <div className="grid grid-cols-2 py-2 gap-2 items-center justify-between">


                  <div>
                    <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                      Codigo
                    </label>
                    <input requiere="true" onChange={captureInputs} value={user.codigo} id="codigo" className="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-blue-700 dark:focus:border-blue-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow" placeholder="6025874 " />
                  </div>

                  <div>
                    <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                      Cedula
                    </label>
                    <input
                      required="true"
                      onChange={captureInputs}
                      value={user.documento}
                      id="documento"

                      className="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-blue-700 dark:focus:border-blue-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow"
                      placeholder="1025878958"
                    />
                  </div>



                </div>



                <div className="grid grid-cols-2 py-2 gap-2">


                  <div className="mt-1">
                    <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                      Nombres
                    </label>
                    <input onChange={captureInputs} value={user.nombres} id="nombres" className="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow" placeholder="CAMILO ANDRES" />
                    <div className="mt-4 items-center cursor-pointer ">
                      <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                        Direccion
                      </label>
                      <input onChange={captureInputs} value={user.direccion || ""} id="direccion" className="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow" placeholder="CR 39 # 41 - 70 ESTE" />
                    </div>

                    <div id="zona" className='w-64 z-40 font-medium px-1 ml-1
                                         mt-10'>
                      <Select
                        defaultValue={{ label: "TIPO", value: "TIPO" }}
                        options={solution}
                        onChange={handleSelectZona}
                      />


                    </div>

                    <div id="estado" className='w-64 z-40 font-medium px-1 ml-1
                                         mt-10'>
                      <Select
                        defaultValue={{ label: "ESTADO", value: "ESTADO" }}
                        options={
                          Estado
                        }
                        onChange={handleSelectEstado}
                      />
                    </div>


                  </div>

                  <div className="mt-1">
                    <div className="md:w-64 md:ml-1 md:mt-0 mt-4">
                      <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                        Apellidos
                      </label>
                      <input onChange={captureInputs} value={user.apellidos} id="apellidos" className="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow" placeholder="HERNANDEZ HERNANDEz" />
                    </div>
                    <div className=" mt-4 items-center md:ml-1 cursor-pointer ">
                      <label className="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">
                        Barrio
                      </label>
                      <input onChange={captureInputs} value={user.barrio} id="barrio" className="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow" placeholder="OASIS" />
                    </div>

                    <div className='w-64 z-40 font-medium ml-1 px-1 mt-10 '>
                      <Select
                        defaultValue={{ label: "FALLA", value: "falla" }}
                        options={falla}
                        onChange={handleSelectFalla}

                      />


                    </div>

                    <div className="w-64 z-40 font-medium ml-1 px-1 mt-10 ">
                      <Select
                        defaultValue={{ label: "Tecnico", value: "Tecnico" }}
                        options={asesores}
                        onChange={handleSelectTecnico}
                      />
                    </div>

                  </div>
                </div>



                <div className="mt-6 flex flex-col   px-10 ">
                  <div className="justify-center text-center py-5">
                    <p className="sm:text-lg md:text-xl lg:text-xl font-bold leading-normal text-gray-800">Comentarios</p>
                  </div>
                  <textarea onChange={captureInputs} value={user.comentarios} id="comentarios" requiere="true" className="w-full bg-transparent border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 resize-none placeholder-gray-500 text-gray-500 dark:text-gray-400" placeholder="Notas" rows={5} />
                  <p className="w-full text-right text-xs pt-1 text-gray-500 dark:text-gray-400">Character Limit: 200</p>
                  <div
                    id="medio de pago"
                    className="block px-2.5 pb-2.5 pt-4 w-56 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                  >

                    Id: {userLogin}
                  </div>
                </div>

                <div className="md:flex justify-between w-full py-4 border-t border-gray-500">
                  <div className="flex items-center">
                    <div className="flex items-center justify-end text-gray-700 dark:text-gray-400">
                      <div
                        className={`mr-3 ${activeIcons.tag ? 'text-blue-500' : ''}`}
                        onClick={() => handleIconClick('tag')}
                        onFocus={() => handleIconFocus('tag')}
                        onBlur={() => setActiveIcons((prevActiveIcons) => ({ ...prevActiveIcons, tag: false }))}
                      >
                        <div className="mr-3 w-5 h-5">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tag" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M11 3L20 12a1.5 1.5 0 0 1 0 2L14 20a1.5 1.5 0 0 1 -2 0L3 11v-4a4 4 0 0 1 4 -4h4" />
                            <circle cx={9} cy={9} r={2} />
                          </svg>
                        </div>

                      </div>
                      <div
                        className={`mr-3 ${activeIcons.bell ? 'text-yellow-400' : ''}`}
                        onClick={() => handleIconClick('bell')}
                        onFocus={() => handleIconFocus('bell')}
                        onBlur={() => setActiveIcons((prevActiveIcons) => ({ ...prevActiveIcons, bell: false }))}
                      >
                        <div className="mr-3 w-5 h-5">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                            <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                          </svg>
                        </div>

                      </div>
                      <div
                        className={`mr-3 ${activeIcons.calendar ? 'text-blue-500' : ''}`}
                        onClick={() => handleIconClick('calendar')}
                        onFocus={() => handleIconFocus('calendar')}
                        onBlur={() => setActiveIcons((prevActiveIcons) => ({ ...prevActiveIcons, calendar: false }))}
                      >
                        <div className="mr-3 w-5 h-5">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x={4} y={5} width={16} height={16} rx={2} />
                            <line x1={16} y1={3} x2={16} y2={7} />
                            <line x1={8} y1={3} x2={8} y2={7} />
                            <line x1={4} y1={11} x2={20} y2={11} />
                            <rect x={8} y={15} width={2} height={2} />
                          </svg>
                        </div>

                      </div>

                      <div
                        className={`mr-3 ${activeIcons.user ? 'text-blue-500' : ''}`}
                        onClick={() => handleIconClick('user')}
                        onFocus={() => handleIconFocus('user')}
                        onBlur={() => setActiveIcons((prevActiveIcons) => ({ ...prevActiveIcons, user: false }))}
                      >
                        <div className="mr-3 w-5 h-5 ">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx={9} cy={7} r={4} />
                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            <path d="M16 11h6m-3 -3v6" />
                          </svg>
                        </div>


                      </div>

                      <div
                        className={`mr-3 ${activeIcons.archive ? 'text-blue-500' : ''}`}
                        onClick={() => handleIconClick('archive')}
                        onFocus={() => handleIconFocus('archive')}
                        onBlur={() => setActiveIcons((prevActiveIcons) => ({ ...prevActiveIcons, archive: false }))}
                      >
                        <div className="mr-3 w-5 h-5 ">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x={3} y={4} width={18} height={4} rx={2} />
                            <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                            <line x1={10} y1={12} x2={14} y2={12} />
                          </svg>
                        </div>

                      </div>



                    </div>
                    <div className="flex items-end px-4">



                      <button type="button" className="px-10 flex justify-between text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm  py-2.5 text-center mr-5 mb-2">
                        <FiX className='w-5 h-5' />
                        <Link to="/ordenes" className='px-2'>Cancelar</Link>
                      </button>

                      <button onClick={guardarDatos} type="button" className="text-gray-900 flex justify-between bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-5 mb-2">
                        <FiSave className='w-5 h-5 ' />
                        <Link to="/ordenes" className='px-2'>Guardar</Link>
                      </button>



                    </div>
                  </div>

                </div>



              </form>

            </div>

          </div>
        </div>
      </div>
    </div>


  )
}

