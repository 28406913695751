import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';



const Grafica = () => {
  // Datos ficticios para las gráficas
  const datosCaja = [30, 45, 25, 55, 40, 60, 35];
  const datosRecaudo = [20, 35, 15, 45, 30, 50, 25];

  const data = {
    labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    datasets: [
      {
        label: 'Base',
        data: datosCaja,
        backgroundColor: '#6366F1',
        borderColor: '#4F46E5',
        borderWidth: 1,
      },
      {
        label: 'Recaudo',
        data: datosRecaudo,
        backgroundColor: '#EC4899',
        borderColor: '#BE185D',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 100,
      },
    },
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="w-96 mx-2">
          <Bar data={data} options={options} />
        </div>
      </div>
    </>
  );
};

export default Grafica;
