import React, { useState } from 'react'
import Facturacion from './Facturacion';
import Cntcaja from '../activities/Cntcaja';
import Sidebar from '../common/Sidebar';
import Navbar from '../common/Navbar';



export default function Cntcaja2(props) {
    const user = props.user;

    return (
        <>
            <div className="w-full h-full bg-white">        
                <div className="flex flex-no-wrap">
                    <Sidebar/>
                    <div className="w-full" style={{marginLeft:"288px"}}>
                        <Navbar />           
                        <div className="container mx-auto py-2   w-auto px-4">
                            <div>
                                <Cntcaja user ={user} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}