import React from 'react'

function Footer() {
    return (
      <footer aria-label="Site Footer" className="bg-write">
      <div className="max-w-screen-xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="flex items-center justify-end">
          <p className="mt-4 text-sm text-gray-500 sm:mt-0 sm:text-right">
            Maximo &copy; 2023. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
    );
  }
  
  export default Footer;
  