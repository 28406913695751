import React, { useState } from 'react';
import logo from '../../assets/logo.png';
import world from '../../assets/colnet.png';
import { FiAlignJustify, FiFile, FiSearch, FiAlertCircle, FiPlus, FiSettings, FiAlertTriangle, FiMenu, FiHome } from "react-icons/fi";
import { ChevronDoubleLeftIcon } from '@heroicons/react/solid';
import { RiBarChartBoxLine } from "react-icons/ri";
import { FcAssistant, FcVoicePresentation, FcCustomerSupport, FcEngineering, FcCalculator, FcCurrencyExchange, FcServices, FcDataRecovery} from "react-icons/fc";

import { IoIosCash, IoIosKeypad } from "react-icons/io";
import { IoChevronBackSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';



const Sidebar = ({ searchResults }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
    const handleSidebarToggle = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    const Menus = [
      { key: "caja", title: "Caja", icon: <FcCalculator />, path: '/principal' },
      { key: "usuario", title: "Usuario", icon: <FcVoicePresentation />, path: '/home' },
      { key: "ordenes", title: "Ordenes de Servicio", icon: <FcAssistant />, path: '/ordenes' },
      { key: "facturacion", title: "Facturacion", icon: <FcCurrencyExchange />, spacing: true, path: '/facturacion' },
      
      { key: "pqr", title: "PQR", icon: <FcCustomerSupport />, spacing: true, path: '/pqr' },
      { key: "crear-usuario", title: "Crear Usuario", icon: <FcDataRecovery />, path: '/crear' },
      { key: "configuraciones", title: "Configuraciones", icon: <FcServices />, path: '/' },
    ];
  
    return (
      <div className="flex">
        <div
          className={`${isSidebarOpen ? "w-72" : "w-20"} fixed shadow border-1 duration-300 h-screen p-5 pt-8 border-1 bg-gradient-to-b from-white via-white to-yellow-300/20 z-10`}
        >
          <IoChevronBackSharp
            className={`absolute cursor-pointer rounded-full -right-3 top-9 w-7 h-7 border-2 border-dark-purple ${!isSidebarOpen && "rotate-180"}`}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          />
  
          <div className="flex  items-center ">
            <img className={`cursor-pointer duration-500 ${isSidebarOpen && "rotate-[360deg] ml-10 h-36 w-42  "}`} src={world}  />
            
          </div>
  
          <ul className="py-6">
            {Menus.map((menu) => (
              <li 
              key={menu.key}
              to={menu.path}
               className={`text-gray-900 hover:border-2 hover:border-blue-500 leading-normal flex 
              items-center gap-x-4 cursor-pointer p-2 hover:text-blue-500 rounded-md ${menu.spacing ? "mt-12" : "mt-7"}`}>
                <span className={`${isSidebarOpen && "rotate-[360deg]"} text-2xl block float-left`}>
                  {menu.icon ? menu.icon : <FiMenu />}
                </span>
                <Link
                  to={{
                    pathname: menu.path,
                    state: { searchResults: searchResults }
                  }}
                  className={`${!isSidebarOpen && "hidden"} origin-left duration-200 text-lg flex-1 underline-offset-8`}>{menu.title}</Link>
              </li>
            ))}
          </ul>
        </div>
  
        <div className="bg-mint text-mint flex items-center fill-current text-center -right-1"></div>
      </div>
    );
  }
  
  export default Sidebar;