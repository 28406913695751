import { useState } from 'react';

function TableTransaction() {
    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Número de elementos por página

  // Datos de ejemplo del historial de transacciones
  const transacciones = [
    { id: 1001, usuario: 'COL_40256', concepto: 'INTERNET_FTTH__20MG_$42.000', pago: "efectivo", estado:"aprobada", monto: 100 },
    { id: 1002, usuario: 'COL_40107', concepto: 'INTERNET_FTTH__50MG_$52.000', pago: "efectivo", estado:"aprobada", monto: 200 },
    { id: 1003, usuario: 'COL_401002', concepto: 'INTERNET_FTTH__20MG_TV$59.000',pago: "efectivo", estado:"aprobada", monto: 300 },
    { id: 1004, usuario: 'COL_4002', concepto: 'INTERNET_FTTH__50MG_TV$69.000', pago: "efectivo", estado:"aprobada", monto: 300 },
    { id: 1004, usuario: 'COL_40984', concepto: 'INTERNET_FTTH__100mG_$72.000',pago: "efectivo", estado:"aprobada", monto: 300 },
    // ... más transacciones
  ];

  // Cálculo de la paginación
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = transacciones.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(transacciones.length / itemsPerPage);

  // Función para cambiar de página
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-bold mb-4">Historial de Transacciones</h1>

      <div className="flex justify-center">
        <div className="w-full">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className='bg-blue-100/90'>
                <th className="py-2 px-4 border-b text-center">N° de Recibo</th>
                <th className="py-2 px-4 border-b text-center">Usuario</th>
                <th className="py-2 px-4 border-b text-center">Concepto</th>
                <th className="py-2 px-4 border-b text-center">Medio de pago</th>
                <th className="py-2 px-4 border-b text-center">Estado</th>
                <th className="py-2 px-4 border-b text-center">Valor</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((transaccion) => (
                <tr key={transaccion.id}>
                  <td className="py-2 px-4 border-b text-center">{transaccion.id}</td>
                  <td className="py-2 px-4 border-b text-center">{transaccion.usuario}</td>
                  <td className="py-2 px-4 border-b text-center">{transaccion.concepto}</td>
                  <td className="py-2 px-4 border-b text-center">{transaccion.pago}</td>
                  <td className="py-2 px-4 border-b text-center">{transaccion.estado}</td>
                  <td className="py-2 px-4 border-b text-center">{transaccion.monto}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-center mt-4">
        <button
          className="py-2 px-4 rounded-l bg-gray-200 hover:bg-gray-300"
          disabled={currentPage === 1}
          onClick={() => changePage(currentPage - 1)}
        >
          Anterior
        </button>
        <div className="px-2">
          Página {currentPage} de {totalPages}
        </div>
        <button
          className="py-2 px-4 rounded-r bg-gray-200 hover:bg-gray-300"
          disabled={currentPage === totalPages}
          onClick={() => changePage(currentPage + 1)}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
}


export default TableTransaction;
