

import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const GraficaMes = () => {
  // Datos ficticios para las gráficas de mes
  const datosCaja = [100, 120, 90, 110, 130, 95, 105, 115, 100, 85, 120, 110];
  const datosRecaudo = [80, 100, 70, 90, 110, 75, 85, 95, 80, 65, 100, 90];

  const data = {
    labels: [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    datasets: [
      {
        label: 'Base',
        data: datosCaja,
        backgroundColor: '#6366F1',
        borderColor: '#4F46E5',
        borderWidth: 1,
        fill: false, // Deshabilita el relleno para la gráfica de línea
      },
      {
        label: 'Recaudo',
        data: datosRecaudo,
        backgroundColor: '#EC4899',
        borderColor: '#BE185D',
        borderWidth: 1,
        fill: false, // Deshabilita el relleno para la gráfica de línea
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 1000, // Valor máximo sugerido en el eje y
      },
    },
  };

  return (
    <div className="flex justify-between">
      <div className="w-96 mx-2">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default GraficaMes;
