
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDP-k6VtfqP-6Mlqg5pPVYi3uR1_g-BQ2o",
  authDomain: "colnet-a4b51.firebaseapp.com",
  projectId: "colnet-a4b51",
  storageBucket: "colnet-a4b51.appspot.com",
  messagingSenderId: "533164809012",
  appId: "1:533164809012:web:d4df8b382ed659e57ee361",
  measurementId: "G-PWVTQ9R0NN"
};


const app = initializeApp(firebaseConfig);



export const  db = getDatabase(app);
export const dbFirestore = getFirestore(app);  
const analytics = getAnalytics(app);
export const auth = getAuth();

export const storage = getStorage(app)



