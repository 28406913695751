import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { async } from '@firebase/util';

import { MdSearch } from "react-icons/md";
import { ChevronRightIcon } from '@heroicons/react/solid';
import { signOut, getAuth } from 'firebase/auth';
import EditFact from '../activities/EditFact';




const Navbar = () => {



  const [show, setShow] = useState(false);
  const [profile, setProfile] = useState(false);
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const fetchUser = async () => {
      const user = auth.currentUser;
      setUser(user);


    };

    fetchUser();
  }, []);



  const logout = async () => {
    await signOut(auth);
  };

  const handleMenuButtonClick = () => {
    setShow(!show);
  };


  return (
    <>

      <nav className="h-16 flex items-center border-black lg:items-stretch justify-end lg:justify-between bg-gradient-to-r from-white via-white to-yellow-300/20 relative z-10">
        <div className="hidden lg:flex w-full pr-6">


          <nav className="text-gray-500 flex items-center justify-center pl-4 pr-96 font-semibold text-sm">
            <ol className="list-none p-0 inline-flex">
              <li className="flex items-center">
                <a href="/">Inicio</a>
                <ChevronRightIcon className="h-4 w-4 mx-2" />
              </li>
              <li className="flex items-center">
                <a href="/">Categoría</a>
                <ChevronRightIcon className="h-4 w-4 mx-2" />
              </li>
              <li className="flex items-center">
                <span>Subcategoría</span>
              </li>
            </ol>
          </nav>

          <div className="w-1/2 hidden lg:flex ">
            <div className="w-full flex items-center justify-end">
              <div className="w-1/2 hidden lg:flex">
                <div className="w-full flex items-center justify-end">
                  <div className="h-32 w-20  mr-16 flex items-center justify-center">
                    <div className="relative cursor-pointer">
                      <Link to="/search" className="flex items-center justify-center hover:underline text-gray-500 hover:text-blue-500">
                        <MdSearch className="w-10 h-10" />
                        <p className="text-lg ">Buscar</p>
                      </Link>
                    </div>


                  </div>

                  <div className="h-full w-20 mr-16 flex items-center justify-center">
                    <div className="flex items-center justify-center hover:underline text-gray-500 hover:text-blue-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-bell text-gray-500  hover:text-blue-500"
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                        <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                      </svg>
                    
                    </div>
                    <p className="text-lg  leading-none hover:text-blue-500 over:underline text-gray-500">Notificaciones</p>
                  </div>

                  <div className="h-full w-20 flex items-center  justify-center mr-6 cursor-pointer text-gray-600">
                    <Link to="/chat" className="flex items-center justify-center hover:underline text-gray-500 hover:text-blue-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-messages hover:text-blue-500 text-gray-500"
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
                        <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
                      </svg>
                    </Link>
                    <p className="text-lg mx-1  leading-none hover:text-blue-500 over:underline text-gray-500">Chat</p>
                  </div>
                </div>
              </div>


              <div className="flex items-center relative cursor-pointer" onClick={() => setProfile(!profile)}>
                <div className="rounded-full">
                  {profile && (
                    <ul className="p-2 w-full border-r-2 border-blue-500 bg-white absolute rounded left-0 shadow mt-12 sm:mt-16">
                      <li className="flex w-full justify-between text-gray-600 hover:text-blue-500 cursor-pointer items-center">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-user"
                            width={28}
                            height={28}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx={12} cy={7} r={4} />
                            <path d="M6 21va-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                          </svg>
                          <span className="text-lg ml-2 font-medium leading-none">Perfil</span>
                        </div>
                      </li>
                      <li className="flex w-full justify-between text-gray-600 hover:text-blue-500 cursor-pointer items-center mt-2">
                        <div onClick={logout} className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-logout"
                            width={28}
                            height={28}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                            <path d="M7 12h14l-3 -3m0 6l3 -3" />
                          </svg>
                          <span className="text-lg ml-2 font-medium leading-none">Salir</span>
                        </div>
                      </li>
                    </ul>
                  )}
                  <div className="relative">
                    <img
                      className="rounded-full h-10 w-10 object-cover"
                      src="https://tuk-cdn.s3.amazonaws.com/assets/components/sidebar_layout/sl_1.png"
                      alt="avatar"
                    />
                    <div className="w-2 h-2 rounded-full bg-green-400 border border-white absolute inset-0 mb-0 mr-0 m-auto" />
                  </div>
                </div>
                <p className="text-lg mx-3 leading-none text-gray-500">{user && user.email}</p>
                <div className="cursor-pointer text-white">
                  <svg
                    aria-haspopup="true"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-down"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="text-gray-600 mr-8 visible lg:hidden relative"
          onClick={handleMenuButtonClick}
        >
          {show ? (
            " "
          ) : (
            <svg
              aria-label="Main Menu"
              aria-haspopup="true"
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-menu cursor-pointer"
              width={30}
              height={30}
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1={4} y1={8} x2={20} y2={8} />
              <line x1={4} y1={16} x2={20} y2={16} />
            </svg>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;