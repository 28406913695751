import React, { useState, useEffect } from "react";
import { getAuth, signOut } from 'firebase/auth';
import { db } from "../../firebase";
import { storage } from "../../firebase";
import { dbFirestore } from "../../firebase";
import { collection, addDoc, getDocs, where } from 'firebase/firestore';
import { ref, uploadBytes } from "firebase/storage";
import { FaAngleDown, FaWifi, FaStoreAlt } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import { uid } from "uid";
import { v4 as uuidv4 } from 'uuid'
import { getDatabase, set, ref as dbRef, push, orderByChild, get, equalTo, query, onValue, update } from "firebase/database";
import { useDropzone } from 'react-dropzone';
import { IoMdAdd, IoMdTrash } from 'react-icons/io';
import { IoSearchOutline, IoSendOutline } from "react-icons/io5"
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import netflix from "../../assets/netflix.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import amazon from "../../assets/amazon.png";
import hbo from "../../assets/hbo.png";
import disney from "../../assets/disney.png";
import win from "../../assets/win.png";
import colnet from "../../assets/colnet.png";
import { FiSave, FiX, FiPrinter } from "react-icons/fi";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdManageAccounts } from "react-icons/md";
import { BsCurrencyDollar } from 'react-icons/bs';








export default function NewClient(props) {

    const userLogin = props.user;
    console.log(userLogin);




    const [mostrarVentana, setMostrarVentana] = useState(false);
    const [texto, setTexto] = useState('');
    const [correoTxt, setCorreoTxt] = useState('');
    const [nombreTxt, setNombreTxt] = useState('');
    const [documentoTxt, setDocumentoTxt] = useState('');
    const [contactoTxt, setContactoTxt] = useState('');
    const [passwordTxt, setPasswordTxt] = useState('');
    const [adressTxt, setAdressTxt] = useState('');
    const [selectedOptionServer, setSelectedOptionServer] = useState('');
    const [selectedOptionActive, setSelectedOptionActive] = useState('');
    const [selectedOptionPlan, setSelectedOptionPlan] = useState('');
    const [selectedOptionTecnico, setSelectedOptionTecnico] = useState('');

    const [primerNombre, setPrimerNombre] = useState("");
    const [segundoNombre, setSegundoNombre] = useState("");
    const [primerApellido, setPrimerApellido] = useState("");
    const [segundoApellido, setSegundoApellido] = useState("");
    const [documento, setDocumento] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [email, setEmail] = useState("");
    const [telefono, setTelefono] = useState("");
    const [direccion, setDireccion] = useState("");
    const [via, setVia] = useState('');
    const [numero, setNumero] = useState("");
    const [letra, setLetra] = useState("")
    const [numeroDos, setNumeroDos] = useState("");
    const [numeroTres, setNumeroTres] = useState("");
    const [letraDos, setLetraDos] = useState("")
    const [barrio, setBarrio] = useState("");
    const [bis, setBis] = useState("");
    const [sufijo, setSufijo] = useState("");
    const [bisDos, setBisDos] = useState("");
    const [sufijoDos, setSufijoDos] = useState("");
    const [complemento, setComplemento] = useState("");
    const [contraseña, setContraseña] = useState("");
    const [confirmarContraseña, setConfirmarContraseña] = useState("");
    const [telefonoAlterno, setTelefonoAlterno] = useState("");
    const [confirmarEmail, setConfirmarEmail] = useState("");
    const [television, setTelevision] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenApp, setIsOpenApp] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionApp, setSelectedOptionApp] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [file, setFile] = useState(null);
    const [fileRp, setFileRp] = useState(null);
    const [filePerfil, setFilePerfil] = useState(null);
    const [searchDoc, setSearchDoc] = useState("");
    const [complementos, setComplementos] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [pais, setPais] = useState('');
    const [clickCount, setClickCount] = useState(0);

    const [contrato, setContrato] = useState("");
    const [lastContract, setLastContract] = useState(0)

    const [newContractNumber, setNewContractNumber] = useState(null);
    const [newContractUpdated, setNewContractUpdated] = useState('');
















    const validateUser = async (email) => {
        try {
            const user = getAuth().currentUser;

            if (user && user.email === email) {
                // El usuario está autenticado y el correo coincide con el registrado en el nodo "roles"
                return true;
            } else {
                // El usuario no está autenticado o el correo no coincide
                return false;
            }
        } catch (error) {
            console.error('Error al validar el usuario:', error);
            throw error;
        }
    };

    const handleClickAdmin = async () => {
        try {
            const currentUser = getAuth().currentUser;

            if (currentUser) {
                const isUserRegistered = await validateUser(currentUser.email);

                if (isUserRegistered) {
                    const querySnapshot = await getDocs(collection(dbFirestore, 'roles'));
                    const roles = [];

                    querySnapshot.forEach((doc) => {
                        const role = doc.data();
                        role.id = doc.id;
                        roles.push(role);
                    });

                    const tecnicoRoles = roles.filter((role) => {
                        return (
                            role.correoTxt === currentUser.email &&
                            role.selectedOptionTecnico === 'Administrador'
                        );
                    });

                    if (tecnicoRoles.length > 0) {
                        const primerTecnico = tecnicoRoles[0];
                        const mensajeBienvenida = `Bienvenido ${primerTecnico.nombreTxt}, tu eres ${primerTecnico.selectedOptionTecnico}`;
                        toast.success(mensajeBienvenida)
                        toggleVentana();
                    } else {
                        throw new Error('El usuario no tiene permisos de administrador');
                    }
                } else {
                    throw new Error('El usuario no está registrado');
                }
            } else {
                throw new Error('El usuario no está autenticado');
            }
        } catch (error) {
            toast.error('Error al validar el usuario o verificar los roles: ' + error.message);
        }
    };















   











    const toggleVentana = () => {
        setMostrarVentana(!mostrarVentana);

    };

    const handleCorreoChange = (event) => {
        setCorreoTxt(event.target.value);
    };

    const handleNombreChange = (event) => {
        setNombreTxt(event.target.value);
    };
    const handleDocumentoChange = (event) => {
        setDocumentoTxt(event.target.value);
    };
    const handleContactoChange = (event) => {
        setContactoTxt(event.target.value);
    };

    const handlePasswordUsersChange = (event) => {
        setPasswordTxt(event.target.value);
    };

    const handleAdressChange = (event) => {
        setAdressTxt(event.target.value);
    };

    const handleTextoChange = (event) => {
        setTexto(event.target.value);
    };







    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmPasswordBlur = () => {
        if (confirmarContraseña !== contraseña) {
            toast.error('Las contraseñas no coinciden');
        }
    };





    function handleClick() {
        setClickCount(clickCount + 1);

        if (clickCount % 2 === 0) {
            setComplementos([...complementos, complemento]);
            setComplemento("");
            setShowInput(false);
        } else {
            setShowInput(true);
        }
    }




    const handleCityChangeLat = (event) => {
        setCiudad(event.target.value);
    };

    const handleCountryChange = (event) => {
        setPais(event.target.value);
    };

    function handleDelete(index) {
        const newComplementos = complementos.filter((_, i) => i !== index);
        setComplementos(newComplementos);
    }






    const handleViaChange = (event) => {
        setVia(event.target.value);
    }


    const handleDatabase = () => {
        writeDatabase();
        writeContratos();

    };



    const writeDatabase = () => {



        const uuid = uid()

        const contratoData = {

            estado: 'creado',
        };

        update(dbRef(db, 'usuarios/' + documento), {


            primerNombre,
            segundoNombre,
            primerApellido,
            segundoApellido,
            documento,
            pais,
            ciudad,
            email,
            telefono,
            direccion,
            barrio,
            contraseña,
            confirmarContraseña,
            telefonoAlterno,
            confirmarEmail,
            television,
            selectedOption,
            selectedOptionApp,
            selectedValue,
            uuid,
            userLogin,
            newContractUpdated
        }

        );


        setNewContractUpdated("")
        setPrimerNombre("");
        setSegundoNombre("");
        setPrimerApellido("");
        setSegundoApellido("");
        setDocumento("");
        setPais("");
        setCiudad("");
        setEmail("");
        setTelefono("");
        setDireccion("");
        setBarrio("");
        setContraseña("");
        setConfirmarContraseña("");
        setTelefonoAlterno("");
        setConfirmarEmail("");
        setTelevision("");
        setSelectedOption("");
        setSelectedOptionApp("");
        setSelectedValue("");







    };

    const writeContratos = async () => {
        const uuid = uid();


        const contratoRef = ref(db, `contratos/` + documento);
        setDocumento("");

        const snapshot = await get(contratoRef);
        const contratoData = snapshot.val();

        if (contratoData) {
            // Si ya existe un contrato para el documento especificado, actualiza el contrato existente
            await update(contratoRef, {
                contrato: newContractUpdated
            });
        } else {
            // Si no existe un contrato para el documento especificado, crea uno nuevo
            await set(contratoRef, {
                uuid: uuid,
                contrato: newContractUpdated
            });
        }
    };


    useEffect(() => {
        getLastContract().then((lastContract) => {
            if (lastContract) {
                setNewContractNumber(lastContract.contrato + 1);
            } else {
                setNewContractNumber(1);
            }
        });
    }, []);




    const getLastContract = async () => {
        try {
            const snapshot = await get(dbRef(db, 'contratos'));
            const contracts = snapshot.val();
            if (contracts) {
                const contractKeys = Object.keys(contracts);
                contractKeys.sort((a, b) => parseInt(b) - parseInt(a));
                const lastContractKey = contractKeys[0];
                const lastContract = contracts[lastContractKey];
                return lastContract;
            } else {
                return null; // Retorna null si no hay contratos
            }
        } catch (error) {
            console.error("Error al obtener el último contrato:", error);
            return null;
        }
    };

    // Función para crear un nuevo contrato
    const getNewContract = async () => {
        try {
            const lastContract = await getLastContract();
            const newContractNumber = lastContract ? `CNT${lastContract.contrato + 4000}` : 'CNT4000';
            const uuid = uid(); // Asegúrate de que uid() esté definido o importado correctamente

            const newContractRef = dbRef(db, `contratos/${newContractNumber}`);
            await set(newContractRef, {
                uuid: uuid,
                contrato: newContractNumber,
            });

            console.log("Nuevo contrato generado:", newContractNumber);
            return newContractNumber;
        } catch (error) {
            console.error("Error al generar el nuevo contrato:", error);
            return null;
        }
    };


    const handleUpdateContract = async () => {
        const newContract = await getNewContract();
        if (newContract) {
            setNewContractUpdated(newContract);
            setNewContractNumber(null); // Elimina este para mantener el número visible
        }
    };

    const handleSave = () => {
        const uuid = uid();
        update(dbRef(db, "contratos/" + documento), {
            uuid: uuid,
            contrato: newContractUpdated,
        });
    };









    const handleOptionSelect = (option) => {

        setSelectedOption(option);
        setIsOpen(false);
    };

    const toggleDropdown = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleOptionAppSelect = (option1) => {
        setSelectedOptionApp(option1);

        setIsOpenApp(false);
    };
    const toggleDropdownApp = (event) => {
        event.preventDefault();
        setIsOpenApp(!isOpenApp);
    };

    const handleSearchDoc = (e) => {
        setSearchDoc(e.target.value);
    };


    const handleFirstNamehange = (e) => {
        setPrimerNombre(e.target.value);
    };

    const handleTwoNameChange = (e) => {
        setSegundoNombre(e.target.value);
    };
    const handleFirstLastNameChange = (e) => {
        setPrimerApellido(e.target.value);
    };
    const handleTwoLastName = (e) => {
        setSegundoApellido(e.target.value);
    };
    const handleDocumentChange = (e) => {
        const documento = e.target.value;
        setDocumento(documento);

    };
    const handleCityChange = (e) => {
        setCiudad(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handlePhoneChange = (e) => {
        setTelefono(e.target.value);
    };
    const handleAddressChange = () => {
        const direccionGenerada = `${via} ${numero} ${letra} ${sufijo} ${bis} ${numeroDos} ${letraDos} ${sufijoDos} ${bisDos} ${numeroTres} ${complementos.join(" ")}`;
        setDireccion(direccionGenerada);

    };

    useEffect(() => {
        handleAddressChange();
    }, [via, numero, letra, sufijo, bis, numeroDos, letraDos, sufijoDos, bisDos, numeroTres, complementos]);


    const handleCiudadChange = (e) => {
        setCiudad(e.target.value);
    };

    const handleBarrioChange = (e) => {
        setBarrio(e.target.value);
    };
    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setContraseña(e.target.value);
    };
    const handleTwoPasswordChange = (e) => {
        const password = e.target.value;
        setConfirmarContraseña(password);


    };


    const handleNumeroChange = (e) => {
        setNumero(e.target.value);
    };
    const handleLetraChange = (e) => {
        setLetra(e.target.value);
    };

    const handleNumeroDosChange = (e) => {
        setNumeroDos(e.target.value);
    };
    const handleNumeroTresChange = (e) => {
        setNumeroTres(e.target.value);
    };
    const handleLetraDosChange = (e) => {
        setLetraDos(e.target.value);
    };


    const handleTwoPhoneChange = (e) => {
        setTelefonoAlterno(e.target.value);
    };
    const handleTwoEmailChange = (e) => {
        setConfirmarEmail(e.target.value);
    };

    const handleSufijoChange = (e) => {
        setSufijo(e.target.value);
    };

    const handleBisChange = (e) => {
        setBis(e.target.value);
    };

    const handleSufijoDosChange = (e) => {
        setSufijoDos(e.target.value);
    };

    const handleBisDosChange = (e) => {
        setBisDos(e.target.value);
    };


    const onDrop = (acceptedFiles) => {
        // Obtener el archivo seleccionado
        const selectedFile = acceptedFiles[0];
        // Actualizar el estado del archivo
        setFile(selectedFile);
    };

    const onDropRp = (acceptedFiles) => {
        // Obtener el archivo seleccionado
        const selectedFileRp = acceptedFiles[0];
        // Actualizar el estado del archivo
        setFileRp(selectedFileRp);
    };
    const onDropPerfil = (acceptedFiles) => {
        // Obtener el archivo seleccionado
        const selectedFilePerfil = acceptedFiles[0];
        // Actualizar el estado del archivo
        setFilePerfil(selectedFilePerfil);
    };

    const folderName = 'cedula';
    const folderNameRp = 'recibo';
    const folderNamePerfil = 'foto de perfil';

    const uploadImage = async (file, documento) => {

        const fileName = documento
        const storageRef = ref(storage, `${folderName}/${fileName}`);

        await uploadBytes(storageRef, file);
        return true;


    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleOptionChangeActive = (e) => {
        setSelectedOptionActive(e.target.value);
    };

    const handleOptionChangeTecnico = (e) => {
        setSelectedOptionTecnico(e.target.value);
    };

    const uploadImageRp = async (fileRp, documento) => {

        const fileName = documento
        const storageRef = ref(storage, `${folderNameRp}/${fileName}`);

        await uploadBytes(storageRef, fileRp);
        return true;




    }

    const uploadImagePerfil = async (filePerfil, documentoTxt) => {

        const fileNamePerfil = documentoTxt
        const storageRef = ref(storage, `${folderNamePerfil}/${fileNamePerfil}`);

        await uploadBytes(storageRef, filePerfil);
        return true;




    }


    const handleUpload = () => {
        if (file && documento) {
            uploadImage(file, documento);
            toast.success("Imagen subida exitosamente");
        } else {
            toast.error("Error al subir la imagen");
            console.log("Debes seleccionar una imagen y un nombre");
        }
    };

    const handleUploadRp = () => {
        if (file && documento) {
            uploadImageRp(file, documento);
            toast.success("Imagen subida exitosamente");
        } else {
            toast.error("Error al subir la imagen");
            console.log("Debes seleccionar una imagen y un nombre");
        }
    };

    const handleUploadPerfil = () => {
        if (filePerfil && documentoTxt) {
            uploadImagePerfil(filePerfil, documentoTxt);
            toast.success("Imagen subida exitosamente");
        } else {
            toast.error("Error al subir la imagen");
            console.log("Debes seleccionar una imagen y un nombre");
        }
    };

    



    useEffect(() => {
        fetch('https://restcountries.com/v3.1/region/americas')
            .then(response => response.json())
            .then(data => {
                setCountries(data);
            });
    }, []);









    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { getRootProps: getRootProps2, getInputProps: getInputProps2, isDragActive: isDragActive2 } = useDropzone({ onDrop: onDropRp });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3, isDragActive: isDragActive3 } = useDropzone({ onDrop: onDropPerfil });


















    return (
        <>
            <div className="flex items-center justify-center" >
                <div className="xl:w-11/12 w-full px-4">

                    <div className="xl:px-24">
                        <div className="px-5 py-4 bg-blue-100 rounded-lg flex items-center justify-between mt-7">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <svg className="address-card" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19 9.99999H20C20.2652 9.99999 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 9.99999 4 9.99999H5V8.99999C5 8.08074 5.18106 7.17049 5.53284 6.32121C5.88463 5.47193 6.40024 4.70026 7.05025 4.05025C7.70026 3.40023 8.47194 2.88462 9.32122 2.53284C10.1705 2.18105 11.0807 1.99999 12 1.99999C12.9193 1.99999 13.8295 2.18105 14.6788 2.53284C15.5281 2.88462 16.2997 3.40023 16.9497 4.05025C17.5998 4.70026 18.1154 5.47193 18.4672 6.32121C18.8189 7.17049 19 8.08074 19 8.99999V9.99999ZM17 9.99999V8.99999C17 7.67391 16.4732 6.40214 15.5355 5.46446C14.5979 4.52678 13.3261 3.99999 12 3.99999C10.6739 3.99999 9.40215 4.52678 8.46447 5.46446C7.52678 6.40214 7 7.67391 7 8.99999V9.99999H17ZM11 14V18H13V14H11Z"
                                            fill="#4B5563"
                                        />
                                    </svg>
                                </div>

                                <p className="text-sm text-gray-800 pl-3">Este contrato explica las condiciones para la prestacion de los servicios entre usted y Colnet Telecomunicaciones</p>
                            </div>
                            <button className="md:block hidden focus:outline-none focus:ring-2 focus:ring-gray-700 rounded">
                                <svg aria-label="Close this banner" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.8337 5.34166L14.6587 4.16666L10.0003 8.825L5.34199 4.16666L4.16699 5.34166L8.82533 10L4.16699 14.6583L5.34199 15.8333L10.0003 11.175L14.6587 15.8333L15.8337 14.6583L11.1753 10L15.8337 5.34166Z" fill="#79808F" />
                                </svg>
                            </button>
                        </div>



                        
                        <Link to="/collaborator"
                        className="px-5 py-4 bg-blue-50 rounded-2xl flex items-center hover:bg-blue-500 hover:text-white justify-between mt-7" onClick={handleClickAdmin}>
                            <div className="flex items-center "  >
                                <div className="flex-shrink-0">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19 9.99999H20C20.2652 9.99999 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 9.99999 4 9.99999H5V8.99999C5 8.08074 5.18106 7.17049 5.53284 6.32121C5.88463 5.47193 6.40024 4.70026 7.05025 4.05025C7.70026 3.40023 8.47194 2.88462 9.32122 2.53284C10.1705 2.18105 11.0807 1.99999 12 1.99999C12.9193 1.99999 13.8295 2.18105 14.6788 2.53284C15.5281 2.88462 16.2997 3.40023 16.9497 4.05025C17.5998 4.70026 18.1154 5.47193 18.4672 6.32121C18.8189 7.17049 19 8.08074 19 8.99999V9.99999ZM17 9.99999V8.99999C17 7.67391 16.4732 6.40214 15.5355 5.46446C14.5979 4.52678 13.3261 3.99999 12 3.99999C10.6739 3.99999 9.40215 4.52678 8.46447 5.46446C7.52678 6.40214 7 7.67391 7 8.99999V9.99999H17ZM11 14V18H13V14H11Z"
                                            fill="#4B5563"
                                        />
                                    </svg>
                                </div>

                                <p className="text-sm text-gray-800 hover:text-white focus-text-white pl-3">Crea un nuevo colaborador (Debes tener permisos de administrador)</p>
                            </div>
                            <button  className="md:block hidden focus:outline-none focus:ring-2 focus:ring-gray-700 rounded">
                                <MdManageAccounts className="w-7 h-7  " />
                            </button>
                       </Link>   
                        

                        <div className="border-b border-gray-300">
                            <div className="py-6 sm:flex items-center mt-10 lg:flex pb-10">
                                <div className="w-80 items-start">
                                    <div className="flex">
                                        <div>
                                            <img src={colnet} alt="" className="w-56 h-40" />
                                        </div>
                                    </div>
                                </div>

                                <div className="py-2 px-1 flex flex-col justify-center ml-96">
                                    <input
                                        type="text"
                                        value={newContractUpdated}
                                        onChange={(e) => setNewContractUpdated(e.target.value)}
                                        className="text-center p-2 text-yellow-600 text-4xl"
                                    />
                                    <button onClick={handleUpdateContract} className="mt-2 toast text-gray-600 font-bold py-2  rounded-lg">
                                        Actualizar
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div
                            id="medio de pago"
                            className=" block px-2.5 pb-2.5 pt-4 w-56 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                        >

                            Id: {userLogin}
                        </div>


                        <div className="mt-16 lg:flex justify-between border-b border-gray-200 pb-16">
                            <div className="w-80">
                                <div className="flex items-center">
                                    <h1 className="text-xl font-medium v leading-5 text-gray-800">Datos Personales</h1>
                                </div>
                                <p className="mt-4 text-sm leading-5 text-gray-600">Búsqueda de personas, complete tipo de documento y número de documento, presione enter para obtener la información.</p>
                            </div>

                            <div className="flex-1 items-center justify-end">
                                <div className="relative">
                                    <input type="text" value={searchDoc} onChange={handleSearchDoc} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="CC / NIT" />
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <IoSearchOutline />
                                    </span>
                                </div>
                                <div className="py-5 h-10 flex justify-center">
                                    <button className="h-10 flex items-center text-gray-900 toast   font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-5 mb-2" onClick={handleClick}>
                                        Buscar
                                        <IoSendOutline className="inline-block ml-2" />
                                    </button>
                                </div>

                            </div>

                        </div>





                        <form >
                            <div className="mt-16 lg:flex justify-between border-b border-gray-200 pb-16">
                                <div className="w-80">
                                    <div className="flex items-center">
                                        <h1 className="text-xl font-medium v leading-5 text-gray-800">Informacion Personal</h1>
                                    </div>
                                    <p className="mt-4 text-sm leading-5 text-gray-600">El usuario debe suministrar informacion verdadera que facilite su atencion</p>
                                </div>
                                <div>
                                    <div className="md:flex items-center lg:ml-24 lg:mt-0 mt-4">


                                        <div className="relative md:w-64">
                                            <input type="text" value={primerNombre} onChange={handleFirstNamehange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label htmlFor="floating_outlined" className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Primer Nombre</label>
                                        </div>
                                        <div className="relative md:w-64 md:ml-12 md:mt-0 mt-4">
                                            <input type="text" value={segundoNombre} onChange={handleTwoNameChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Segundo nombre </label>
                                        </div>


                                    </div>

                                    <div className="md:flex items-center lg:ml-24 mt-8">


                                        <div className="relative md:w-64">
                                            <input type="text" value={primerApellido} onChange={handleFirstLastNameChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Primer Apellido</label>
                                        </div>
                                        <div className="relative md:w-64 md:ml-12 md:mt-0 mt-4">
                                            <input type="text" value={segundoApellido} onChange={handleTwoLastName} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Segundo Apellido </label>
                                        </div>


                                    </div>

                                    <div className="md:flex items-center lg:ml-24 mt-8">


                                        <div className="relative md:w-64">
                                            <input id="documento" type="text" value={documento} onChange={handleDocumentChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">CC/NIT</label>
                                        </div>
                                        <div className="relative md:w-64 md:ml-12 md:mt-0 mt-4">
                                            <input type="text" value={telefono} onChange={handlePhoneChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Telefono de contacto</label>
                                        </div>



                                    </div>

                                    <div className="md:flex items-center lg:ml-24 mt-8">

                                        <div>

                                            <div className="relative md:w-64">
                                                <select
                                                    onChange={handleCountryChange}
                                                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                >
                                                    <option value="">Selecciona un país</option>
                                                    {countries.map(country => (
                                                        <option key={country.name.common} value={country.name.common}>
                                                            {country.name.common}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label
                                                    className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                                                >
                                                    Pais
                                                </label>
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                    <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="relative md:w-64 md:ml-12 md:mt-0 mt-4">
                                            <input type="text" value={email} onChange={handleEmailChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Departamento</label>
                                        </div>



                                    </div>

                                    <div className="md:flex items-center lg:ml-24 mt-8">


                                        <div className="relative md:w-64">
                                            <input type="text" value={ciudad} onChange={handleCiudadChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Ciudad</label>

                                        </div>

                                        <div className="relative md:w-64 md:ml-12 md:mt-0 mt-4">
                                            <input type="text" value={barrio} onChange={handleBarrioChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">P.H/Barrio </label>
                                        </div>


                                    </div>

                                </div>
                            </div>



                            <div className="mt-10 flex justify-between">
                                <div className="w-80">
                                    <h1 className="text-xl font-medium pr-2 leading-5 text-gray-800">Direccion</h1>

                                    <p className="mt-4 text-sm leading-5 text-gray-600">Confirma los datos con el recibo publico</p>
                                </div>
                                <div

                                    className="flex-1 pl-2 items-center text-center justify-center mr-2">
                                    <input type="text" value={direccion} onChange={(e) => setDireccion(e.target.value)}
                                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "></label>
                                </div>
                            </div>

                            <div className="grid grid-cols-3 md:grid-cols-6 gap-4 lg:ml-10 mt-8">

                                <div className="relative md:w-32 ml-10">
                                    <select
                                        required
                                        value={via}
                                        onChange={handleViaChange}
                                        className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    >
                                        <option value="" disabled selected hidden>Via</option>
                                        <option value="Avenida">Avenida</option>
                                        <option value="Autopista">Autopista</option>
                                        <option value="Calle">Calle</option>
                                        <option value="Carrera">Carrera</option>
                                        <option value="Diagonal">Diagonal</option>
                                        <option value="Transversal">Transversal</option>
                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>

                                </div>

                                <div className="relative md:w-32  md:ml-10 md:mt-0 ">
                                    <input required type="number" value={numero} onChange={handleNumeroChange} className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">


                                    </label>
                                </div>
                                <div className="relative md:w-32 md:ml-10">
                                    <select

                                        value={letra}
                                        onChange={handleLetraChange}
                                        className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    >
                                        <option value="" disabled selected hidden>Letra</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="E">E</option>
                                        <option value="F">F</option>
                                        <option value="G">G</option>
                                        <option value="H">H</option>
                                        <option value="I">I</option>
                                        <option value="J">J</option>
                                        <option value="K">K</option>
                                        <option value="L">L</option>
                                        <option value="M">M</option>
                                        <option value="N">N</option>
                                        <option value="O">O</option>
                                        <option value="P">P</option>
                                        <option value="Q">Q</option>
                                        <option value="R">R</option>
                                        <option value="S">S</option>
                                        <option value="T">T</option>
                                        <option value="U">U</option>
                                        <option value="V">V</option>
                                        <option value="W">W</option>
                                        <option value="X">X</option>
                                        <option value="Y">Y</option>
                                        <option value="Z">Z</option>


                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>

                                </div>

                                <div className="relative md:w-32 md:ml-10">
                                    <div className="flex items-center">
                                        <select

                                            value={sufijo}
                                            onChange={handleSufijoChange}
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        >
                                            <option value="" disabled selected hidden>Sufijo</option>
                                            <option value="Este">Este</option>
                                            <option value="Norte">Norte</option>
                                            <option value="Oriente">Oriente</option>
                                            <option value="Occidente">Occidente</option>
                                            <option value="Oeste">Oeste</option>
                                            <option value="Sur">Sur</option>
                                        </select>
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="relative md:w-32 md:ml-10">
                                    <select

                                        value={bis}
                                        onChange={handleBisChange}
                                        className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    >
                                        <option value="" disabled selected hidden>Bis</option>
                                        <option value="Bis">Bis</option>

                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>


                                </div>

                                <div className="relative text-center items-center ml-10 md:w-20">
                                    <label className="absolute text-3xl text-blue-500 dark:text-gray-400 duration-300 ">#</label>
                                </div>

                            </div>


                            <div className="grid grid-cols-3 md:grid-cols-6 gap-4 lg:ml-10 mt-8">

                                <div className="relative md:w-32 ml-10">
                                    <input required type="number" value={numeroDos} onChange={handleNumeroDosChange} className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 "></label>
                                </div>
                                <div className="relative md:w-32 md:ml-10">
                                    <select

                                        value={letraDos}
                                        onChange={handleLetraDosChange}
                                        className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    >
                                        <option value="" disabled selected hidden>Letra</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                        <option value="E">E</option>
                                        <option value="F">F</option>
                                        <option value="G">G</option>
                                        <option value="H">H</option>
                                        <option value="I">I</option>
                                        <option value="J">J</option>
                                        <option value="K">K</option>
                                        <option value="L">L</option>
                                        <option value="M">M</option>
                                        <option value="N">N</option>
                                        <option value="O">O</option>
                                        <option value="P">P</option>
                                        <option value="Q">Q</option>
                                        <option value="R">R</option>
                                        <option value="S">S</option>
                                        <option value="T">T</option>
                                        <option value="U">U</option>
                                        <option value="V">V</option>
                                        <option value="W">W</option>
                                        <option value="X">X</option>
                                        <option value="Y">Y</option>
                                        <option value="Z">Z</option>


                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>

                                </div>
                                <div className=" relative md:w-32 md:ml-10">
                                    <select

                                        value={sufijoDos}
                                        onChange={handleSufijoDosChange}
                                        className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    >
                                        <option value="" disabled selected hidden>Sufijo</option>
                                        <option value="Este">Este</option>
                                        <option value="Norte">Norte</option>
                                        <option value="Oriente">Oriente</option>
                                        <option value="Occidente">Occidente</option>
                                        <option value="Oeste">Oeste</option>
                                        <option value="Sur">Sur</option>
                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>

                                </div>




                                <div className="relative md:w-32 md:ml-10">
                                    <select

                                        value={bisDos}
                                        onChange={handleBisDosChange}
                                        className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    >
                                        <option value="" disabled selected hidden>Bis</option>
                                        <option value="Bis">Bis</option>

                                    </select>
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ transform: "rotate(90deg)" }}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>


                                </div>


                                <div className="md:w-32  md:ml-10 ">
                                    <input required type="number" value={numeroTres} onChange={handleNumeroTresChange} className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label
                                        className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 
                                     scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2
                                      peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                      peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75
                                       peer-focus:-translate-y-4 left-1 "></label>
                                </div>


                            </div>



                            <div className="flex lg:ml-10 mt-8 pb-16 border-b border-gray-200">
                                <button
                                    className="text-gray-900  flex justify-between  bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-5 mb-2"
                                    onClick={handleClick}
                                >
                                    <IoMdAdd className="inline-block mr-2" />
                                    Complemento
                                </button>

                                {showInput && (
                                    <div className="px-5">
                                        <input
                                            className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            type="text"
                                            value={complemento}
                                            onChange={(e) => setComplemento(e.target.value)}
                                        />
                                    </div>
                                )}

                                {complementos.map((comp, index) => (
                                    <div key={comp} className="px-5">
                                        <div className="flex items-center">
                                            <input
                                                className="block px-2.5 pb-2.5 pt-4 w-32 text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                type="text"
                                                value={comp}
                                                readOnly
                                            />
                                            <button
                                                className="ml-2 w-10 h-10 text-red-500 hover:text-red-700"
                                                onClick={() => handleDelete(index)}
                                            >
                                                <IoMdTrash />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <div className="mt-16 lg:flex justify-between items-center border-b border-gray-200 pb-16 mb-4">
                                <div className="w-80 mx-auto">
                                    <div className="flex items-center">
                                        <h1 className="text-xl font-medium pr-2 leading-5 text-gray-800">Seguridad</h1>
                                    </div>
                                    <p className="mt-4 text-sm leading-5 text-gray-600">La informacion suministrada es personal e intrasferible </p>
                                </div>
                                <div>
                                    <div className="mx-auto md:flex items-center lg:ml-24 mt-8">

                                        <div className="relative md:w-64">
                                            <input
                                                required
                                                type={showPassword ? 'text' : 'password'}
                                                value={contraseña}
                                                onChange={handlePasswordChange}
                                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                            />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                                Contraseña
                                            </label>
                                            <button
                                                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                                                onClick={handleShowPassword}
                                            >
                                                {showPassword ? <FaEyeSlash /> : <FaEye className="items-center text-center" />}
                                            </button>
                                        </div>
                                        <div className="relative md:w-64 md:ml-12 md:mt-0 mt-4">
                                            <ToastContainer />
                                            <input
                                                required
                                                type={showPassword ? 'text' : 'password'}
                                                value={confirmarContraseña} onChange={handleTwoPasswordChange} onBlur={handleConfirmPasswordBlur}
                                                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                            />

                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                                                Confirmar Contraseña
                                            </label>
                                            <button
                                                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                                                onClick={handleShowPassword}
                                            >
                                                {showPassword ? <FaEyeSlash /> : <FaEye className="items-center text-center" />}
                                            </button>
                                        </div>

                                    </div>
                                    <div className="mx-auto md:flex items-center lg:ml-24 mt-8">
                                        <div className="relative md:w-64">
                                            <input type="email" value={confirmarEmail} onChange={handleTwoEmailChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Email</label>

                                        </div>
                                        <div className="relative md:w-64 md:ml-12 md:mt-0 mt-4">
                                            <input type="number" value={telefonoAlterno} onChange={handleTwoPhoneChange} className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-blue-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                            <label className="absolute text-sm text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ">Telefono Alternativo</label>
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className="mt-16 lg:flex justify-between border-b border-gray-300 pb-16 mb-4">
                                <div className="w-80">
                                    <div className="flex items-center">
                                        <h1 className="text-xl font-medium pr-2 leading-5 text-gray-800">Servicios</h1>
                                    </div>
                                    <p className="mt-4 text-sm leading-5 text-gray-600">Con este contrato nos comprometemos a prestarle los servicios que usted elija </p>
                                </div>
                                <div>
                                    <div className="md:flex items-center lg:ml-24 lg:mt-0 mt-4">
                                        <div className="relative">


                                            <button
                                                onClick={toggleDropdown}
                                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                            >
                                                <FaStoreAlt className="mr-2" />
                                                {selectedOption ? selectedOption : 'Internet'}
                                                <FaAngleDown className="ml-2" />
                                            </button>

                                            {isOpen && (
                                                <div className="absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-2 ring-black ring-opacity-5">
                                                    <button
                                                        onClick={() => handleOptionSelect('20_MG')}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <FaWifi className="mr-2" />
                                                        INTERNET__20MG_$42.000
                                                    </button>
                                                    <button
                                                        onClick={() => handleOptionSelect('50_MG')}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <FaWifi className="mr-2" />
                                                        INTERNET__50MG_$52.000
                                                    </button>
                                                    <button
                                                        onClick={() => handleOptionSelect('100_MG')}
                                                        className="block px-4 py-2 justify-between text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <FaWifi className="mr-2" />
                                                        INTERNET__100MG_$62.000
                                                    </button>
                                                    <button
                                                        onClick={() => handleOptionSelect('300_MG')}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <FaWifi className="mr-2" />
                                                        INTERNET__300MG_$92.000
                                                    </button>


                                                </div>
                                            )}

                                        </div>
                                        <div className="relative">
                                            <button
                                                onClick={toggleDropdownApp}
                                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                            >
                                                <FaStoreAlt className="mr-2" />
                                                {selectedOptionApp ? selectedOptionApp : 'Apps Favoritas'}
                                                <FaAngleDown className="ml-2" />
                                            </button>

                                            {isOpenApp && (
                                                <div className="absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-2 ring-black ring-opacity-5">
                                                    <button
                                                        onClick={() => handleOptionAppSelect('Netflix')}
                                                        className="block px-4 py-2 text-sm  text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <div className=" justify-between">
                                                            <img src={netflix} className="mr-2" />


                                                        </div>


                                                    </button>

                                                    <button
                                                        onClick={() => handleOptionAppSelect('Amazon')}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <img src={amazon} className="mr-2" />

                                                    </button>
                                                    <button
                                                        onClick={() => handleOptionAppSelect('Hbo')}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <img src={hbo} className="mr-2" />

                                                    </button>
                                                    <button
                                                        onClick={() => handleOptionAppSelect('Disney')}
                                                        className="block px-4 py-2 justify-between text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <img src={disney} className="mr-2" />

                                                    </button>
                                                    <button
                                                        onClick={() => handleOptionAppSelect('Win+')}
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100"
                                                    >
                                                        <img src={win} className="mr-2" />

                                                    </button>

                                                </div>


                                            )}
                                        </div>

                                    </div>

                                </div>
                            </div>


                            <div
                                {...getRootProps()}
                                className={`py-10 border-b border-gray-300 flex items-center justify-center w-full ${isDragActive ? 'border-blue-500' : 'border-gray-400'
                                    }`}
                            >
                                <ToastContainer />
                                <label htmlFor="dropzone-fileOne" className="flex flex-col items-center justify-center w-full h-80 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-blue-100 dark:border-blue-600 dark:hover:border-blue-500 dark:hover:bg-blue-600">

                                    {!fileRp ? (
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Cargar Documentos</span> Cedula de ciudadania</p>
                                        </div>
                                    ) : null}


                                    <input {...getInputProps()} />
                                    {file ? (
                                        <>

                                            <div className="flex flex-col items-center justify-center h-screen">

                                                <div className=" py-10">
                                                    <p className="mt-10">Archivo seleccionado: {file.name}</p>
                                                </div>

                                                {file.type === 'application/pdf' ? (
                                                    <iframe src={URL.createObjectURL(file)} title={file.name} className="w-full h-32" />
                                                ) : (
                                                    <img src={URL.createObjectURL(file)} alt={file.name} className="w-full h-32 object-contain" />
                                                )}
                                                <div className="py-8">
                                                    <svg
                                                        htmlFor="enviar el archivo"
                                                        onClick={handleUpload} aria-hidden="true" className="w-14 h-14 mb-8  text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                </div>

                                            </div>


                                        </>
                                    ) : (
                                        <div className="flex items-center">
                                            <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M14 18H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2zM6 4v12h8V4H6z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                        </div>

                                    )}

                                </label>
                            </div>





                            <div
                                {...getRootProps2()}
                                className={`py-10 border-b border-gray-300 flex items-center justify-center h-full w-full ${isDragActive2 ? 'border-blue-500' : 'border-gray-400'
                                    }`}
                            >
                                <ToastContainer />

                                <label htmlFor="Recibo Publico" className="flex flex-col items-center justify-center w-full h-80 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-blue-100 dark:border-blue-600 dark:hover:border-blue-500 dark:hover:bg-blue-600">
                                    {!fileRp ? (
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Cargar Documentos</span> Recibo Publico</p>
                                        </div>
                                    ) : null}

                                    <input {...getInputProps2()} />
                                    {fileRp ? (
                                        <>
                                            <div className="flex flex-col  py-10 items-center justify-center h-screen">
                                                <div className=" py-10">
                                                    <p className="mt-10">Archivo seleccionado: {fileRp.name}</p>
                                                </div>
                                                {fileRp.type === 'application/pdf' ? (
                                                    <iframe src={URL.createObjectURL(fileRp)} title={fileRp.name} className="w-full h-32" />
                                                ) : (
                                                    <img src={URL.createObjectURL(fileRp)} alt={fileRp.name} className="w-full h-32 object-contain" />
                                                )}
                                                <div className="py-8">
                                                    <svg
                                                        htmlFor="enviar el archivo"
                                                        onClick={handleUploadRp} aria-hidden="true" className="w-14 h-14 mb-8  text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="flex items-center ">
                                            <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M14 18H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2zM6 4v12h8V4H6z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                        </div>
                                    )}



                                </label>
                            </div>











                            <div className="mx-auto max-w-xl text-center">


                                <p className="mx-auto mt-4 py-5 text-gray-500">
                                    Las instalaciones manejan un tiempo estimado de 5 dias habiles en ejecutarse
                                </p>



                                <div className="py-10 flex justify-beteween ">



                                    <button type="button" className="px-10 flex justify-between text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm  py-2.5 text-center mr-5 mb-2">
                                        <FiX className='w-5 h-5' />
                                        <Link to="/pqr" className='px-2'>Cancelar</Link>
                                    </button>
                                    <button type="button" className="text-white flex justify-between bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-5 mb-2">
                                        <FiPrinter className='w-5 h-5' />
                                        <Link to="/pdf" className='px-2'>Imprimir</Link>
                                    </button>
                                    <button onClick={handleDatabase} type="submit" className="text-gray-900  flex justify-between  bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-5 mb-2">

                                        <FiSave className='w-5 h-5' />
                                        <Link to="/home" className='px-2'>Guardar</Link>
                                    </button>



                                </div>



                            </div>

                        </form>












                    </div>
                </div>
            </div>
        </>
    );
}


