import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TimePicker = ({ onChange }) => {
  const [selectedTime, setSelectedTime] = useState(null);

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  
    const selectedHour = String(time.getHours()).padStart(2, '0');
    const selectedMinute = String(time.getMinutes()).padStart(2, '0');
    const formattedTime = `${selectedHour}:${selectedMinute} ${time.getHours() >= 12 ? 'PM' : 'AM'}`;
  
    onChange(formattedTime); // Llamada a la función de devolución de llamada (callback) con la hora seleccionada formateada
  };

  return (
    <div className="relative">
      <DatePicker
        selected={selectedTime}
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={120}
        timeCaption="Hora"
        dateFormat="h:mm aa"
        popperClassName="timepicker-popper"
        className="form-input bg-white text-gray-700 shadow-sm border border-blue-200 hover:border-2 hover:border-blue-500 w-64 p-2 rounded-lg"
      />
      <div className="absolute right-0 top-0 mt-2 mr-2">
        <button className="btn btn-primary">
          <svg
            className="w-4 h-4 text-blue-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default TimePicker;

