
import React from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import logo from '../src/assets/colnet.png'
import { ToastContainer, toast } from 'react-toastify';
import background from "./assets/background.png";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../src/Components/common/Footer"




export default function Login({
    setUser,
    setAuthState

}) {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleLogin = (event) => {
        event.preventDefault();
      
        if (email !== '' && password !== '') {
          signInWithEmailAndPassword(auth, email, password)
            .then(() => {
              console.log(email);
              console.log(auth);
              toast.success('¡Inicio de sesión exitoso!' + email);
              setTimeout(() => {
                
                setAuthState('Home');
              }, 2000); // Establecer el estado a 'Home' y redirigir después de 2 segundos
            })
            
            .catch((error) => {
              toast.error('Error: ' + error.message);
            });
        } else {
          toast.error('Por favor, ingresa un correo electrónico y una contraseña');
        }
      };

    return (
        <div>
            <div className='grid grid-cols-1  h-screen w-full '>
                <ToastContainer />


                <div className='bg-pack-train bg-cover flex flex-col justify-center'>
                    <form className='max-w-[400px] w-full mx-auto bg-white p-8 px-8 rounded-xl shadow-lg border-2 border-blue-500 shadow-blue-500'>

                        <div className="bg-mint text-mint fill-current text-center">
                            <img className='' src={logo} width="300"
                                height="300" />

                        </div>

                        <div className='flex flex-col text-black py-2'>
                            <label>Email</label>
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='rounded-lg bg-blue-100 mt-2 p-2 focus:border-blue-500 focus:bg-blue-100 focus:outline' type="text" />
                        </div>
                        <div className='flex flex-col  text-black py-2'>
                            <label>Password</label>
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className='rounded-lg bg-blue-100 mt-2 p-2 focus:border-blue-500 focus:bg-blue-100 focus:outline' type="password" />
                        </div>
                        <div className='flex justify-between  text-black py-2'>
                            <p className='flex items-center'><input className='mr-2' type="checkbox" /> Recuerdame </p>
                            <p className="text-sm">Olvidaste tu contraseña</p>
                        </div>
                        <button
                            onClick={handleLogin}
                            className='w-full my-5 py-2 bg-blue-500 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/40 text-white hover:border-2 hover-border-white font-semibold rounded-lg'>Login</button>
                    </form>

                    <footer aria-label="Site Footer" className="relative ">
                        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center sm:justify-end">


                                <p className="mt-4 text-end text-sm text-white lg:mt-0 lg:text-right">
                                    Maximo &copy; 2023. All rights reserved.
                                </p>
                            </div>
                        </div>
                    </footer>
                    
                </div>



            </div>

           

        </div>



    )
}