
import React, { useState } from 'react';
import Sidebar from '../common/Sidebar';
import Navbar from '../common/Navbar';
import NewClient from './NewClient';

export default function NewClient2(props) {
    const user = props.user;

  return (
    <>
        <div className="w-full h-full bg-white">        
            <div className="flex flex-no-wrap">
                <Sidebar/>
                <div className="w-full" style={{marginLeft:"288px"}}>
                    <Navbar />           
                    <div className="container mx-auto py-2   w-auto px-4">
                        <div>
                            <NewClient user ={user} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);
}
