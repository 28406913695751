import React, { useState } from 'react'

import Sidebar from '../common/Sidebar';
import Navbar from '../common/Navbar';
import Search from './Search';
import Home from './Home'




export default function Search2() {

    const [searchResults, setSearchResults] = useState([]);

    const handleSearchResults = (results) => {
        setSearchResults(results);
        
      };

    return (
        <>
            <div className="w-full h-full bg-white">        
                <div className="flex flex-no-wrap">
                    <Sidebar searchResults={searchResults}/>
                    <div className="w-full" style={{marginLeft:"288px"}}>
                        <Navbar />           
                        <div className="container mx-auto py-2   w-auto px-4">
                            <div>
                                <Search onSearchResults={handleSearchResults} />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}