import React, { useState } from "react";
import DatePicker from "react-datepicker";
import setting from "../../assets/setting.png";

export default function Instalacion(props) {

    const userLogin = props.user;
    console.log(userLogin);

    const constanteRecibida = props.constante;

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    return (
        <div>
            <div id="popup" className="inset-0 z-50 right-100 py-12">
                <div className="flex w-full justify-center ">
                    <div className="bg-white dark:bg-gray-800 shadow-2xl  shadow-blue-500/50 hover:shadow-blue-500/40 w-full md:w-11/12 pt-10 px-10 max-w-2xl z-50">
                        <div className="container flex flex-col w-full h-auto justify-between">
                            <div>
                                <div className="flex w-full justify-between items-center">
                                    <h2 className="text-4xl font-bold dark:text-gray-100 text-gray-900">Orden de Instalacion</h2>
                                    <img src={setting} width="150" height="150"></img>

                                </div>
                                <div className="grid grid-cols-2 py-9 gap-2">
                                    <div>
                                        <p className="text-base font-medium leading-none text-gray-700 text-start mr-2">Internet</p>
                                        <select className="focus:outline-none bg-transparent py-2 ml-1">

                                            <option className="text-sm text-indigo-800">@ 20 Megas</option>
                                            <option className="text-sm text-indigo-800">@ 50 Megas</option>
                                            <option className="text-sm text-indigo-800">@ 100 Megas</option>
                                            <option className="text-sm text-indigo-800">@ 300 Megas</option>
                                        </select>
                                    </div>

                                    <div>
                                        <p className="text-base font-medium leading-none text-gray-700 text-start mr-2">Television</p>
                                        <select className="focus:outline-none bg-transparent py-2 ml-1">

                                            <option className="text-sm text-indigo-800">BlueTv + Netflix</option>
                                            <option className="text-sm text-indigo-800">BlueTv + AmazonPrime</option>
                                            <option className="text-sm text-indigo-800">BlueTv + HBO</option>
                                            <option className="text-sm text-indigo-800">BlueTv + WinSport</option>
                                        </select>
                                    </div>


                                </div>



                                <div className="grid grid-cols-2 py-9 gap-2">

                                    <div className="mt-12">
                                        <div className="checkbox items-center cursor-pointer flex">
                                            <label className="dark:text-gray-400 text-base">
                                                {" "}
                                                <input className="mr-4" type="checkbox" defaultValue />
                                                Modem{" "}
                                            </label>
                                        </div>
                                        <div className="checkbox mt-4 items-center cursor-pointer flex">
                                            <label className="dark:text-gray-400 text-base">
                                                {" "}
                                                <input className="mr-4" type="checkbox" defaultValue />
                                                Repetidor{" "}
                                            </label>
                                        </div>
                                        <div className="checkbox mt-4 items-center cursor-pointer flex">
                                            <label className="dark:text-gray-400 text-base">
                                                {" "}
                                                <input className="mr-4" type="checkbox" defaultValue />
                                                Punto Adicional{" "}
                                            </label>
                                        </div>


                                    </div>

                                    <div className="mt-12">
                                        <div className="checkbox items-center cursor-pointer flex">
                                            <label className="dark:text-gray-400 text-base">
                                                {" "}
                                                <input className="mr-4" type="checkbox" defaultValue />
                                                Android TV{" "}
                                            </label>
                                        </div>
                                        <div className="checkbox mt-4 items-center cursor-pointer flex">
                                            <label className="dark:text-gray-400 text-base">
                                                {" "}
                                                <input className="mr-4" type="checkbox" defaultValue />
                                                Equipo Adicional{" "}
                                            </label>
                                        </div>
                                        <div className="checkbox mt-4 items-center cursor-pointer flex">
                                            <label className="dark:text-gray-400 text-base">
                                                {" "}
                                                <input className="mr-4" type="checkbox" defaultValue />
                                                Equipo Nuevo{" "}
                                            </label>
                                        </div>


                                    </div>
                                </div>
                                <div className=" py-2 gap-2">
                                    <p className="text-base font-medium leading-none text-gray-700 text-start mr-2">Fecha de Instalacion</p>

                                </div>

                                <div className=" py-2 gap-2">
                                    <div class="relative">
                                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none text-center border-l-4 border-green-500  w-full p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                                        </div>

                                        <DatePicker
                                            className="text-center border-l border-red w-40 p-3 rounded text-sm  outline-none  focus:ring-0 bg-transparent"
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat="MMMM d, yyyy "
                                        />
                                    </div>





                                </div>

                                <div className="mt-10 border-b border-gray-400">
                                    <input className="w-full text-xl bg-transparent font-bold pb-2 focus:outline-none placeholder-gray-400" placeholder="Observaciones" type="text" />
                                </div>
                            </div>
                            <div className="flex items-center py-12 mb-8">
                                <div className="flex items-center px-3 py-2 border-gray-300 border rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none">
                                        <circle cx="6.00004" cy="6.66667" r="4.66667" stroke="#4A5568" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6 4.66663V6.66663H7.33333" stroke="#4A5568" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M2.66671 0.666626L0.833374 1.99996" stroke="#4A5568" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.33337 0.666626L11.1667 1.99996" stroke="#4A5568" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p className="text-gray-600 dark:text-gray-400 ml-2 text-xs">7 Sept, 23:00</p>
                                </div>
                                <div className="px-3 py-2 border border-gray-300 rounded-lg ml-5">
                                    <p className="text-gray-600 dark:text-gray-400 text-xs">Personal Work</p>
                                </div>
                            </div>
                        </div>
                        <div
                            id="medio de pago"
                            className="block px-2.5 pb-2.5 pt-4 w-56 text-sm text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                        >

                            Id: {userLogin}
                        </div>
                        <div className="mt-4 md:mt-0">
                            <div>
                                <p className="text-base text-indigo-700 dark:text-indigo-600 mb-6">This task is scheduled for 9th September 2020 at 18:00</p>
                            </div>
                            <div className="md:flex justify-between w-full py-4 border-t border-gray-400">
                                <div className="flex items-center">
                                    <div className="flex items-center justify-end text-gray-700 dark:text-gray-400">
                                        <div className="mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-tag" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <path d="M11 3L20 12a1.5 1.5 0 0 1 0 2L14 20a1.5 1.5 0 0 1 -2 0L3 11v-4a4 4 0 0 1 4 -4h4" />
                                                <circle cx={9} cy={9} r={2} />
                                            </svg>
                                        </div>
                                        <div className="mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                                                <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                                            </svg>
                                        </div>
                                        <div className="mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <rect x={4} y={5} width={16} height={16} rx={2} />
                                                <line x1={16} y1={3} x2={16} y2={7} />
                                                <line x1={8} y1={3} x2={8} y2={7} />
                                                <line x1={4} y1={11} x2={20} y2={11} />
                                                <rect x={8} y={15} width={2} height={2} />
                                            </svg>
                                        </div>
                                        <div className="mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <circle cx={9} cy={7} r={4} />
                                                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                <path d="M16 11h6m-3 -3v6" />
                                            </svg>
                                        </div>
                                        <div className="mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <rect x={3} y={4} width={18} height={4} rx={2} />
                                                <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                <line x1={10} y1={12} x2={14} y2={12} />
                                            </svg>
                                        </div>
                                        <div className="mr-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <circle cx={5} cy={12} r={1} />
                                                <circle cx={12} cy={12} r={1} />
                                                <circle cx={19} cy={12} r={1} />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 md:mt-0">
                                    <button className="text-sm text-white focus:outline-none py-3 px-6 hover:bg-blue-500 bg-blue-700 rounded-md">Save Orden</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
