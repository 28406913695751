import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { FiPlus } from "react-icons/fi"
import { toast, ToastContainer } from 'react-toastify';
import { BsCurrencyDollar } from 'react-icons/bs';
import { BiEraser } from 'react-icons/bi';
import { getDatabase, ref, query, orderByChild, equalTo, onValue, orderByKey, set, get, remove } from 'firebase/database';
import { useLocation, useNavigate } from 'react-router-dom';
import user from "../../assets/user.png"
import factur from "../../assets/factur.png"
import contact from "../../assets/contact.png"
import { Result } from 'postcss';
import Select from 'react-select';


export default function Facturacion(props, { onSearchResults }) {


    const userLogin = props.user;
    console.log(userLogin);

    const constanteRecibida = props.constante;

    const [show, setShow] = useState(null)

    const [isClicked, setIsClicked] = useState(false);

    const [mostrarVentana, setMostrarVentana] = useState(false);
    const [mostrarVentanaPago, setMostrarVentanaPago] = useState(false);
    const [texto, setTexto] = useState('');
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [clicked, setClicked] = useState(false);
    const location = useLocation();
    const detail = location.state ? location.state.detail : null;
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState('');
    const [facturaDetalles, setFacturaDetalles] = useState(null);
    const [facturaTotal, setFacturaTotal] = useState(0);
    const [facturaSeleccionada, setFacturaSeleccionada] = useState(null); // Estado para almacenar la factura seleccionada







    const handleButtonClick = (value) => {
        setInput(prevInput => prevInput + value);
    };

    const handleClear = () => {
        setInput('');
    };

    const handleCalculate = () => {
        try {
            setInput(eval(input).toString());
        } catch {
            setInput('Error');
        }
    };


    console.log(userLogin);
    console.log(detail);







    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedItems(/* Add your item data here */);
        } else {
            setSelectedItems([]);
        }
    };

    const toggleDetails = (index) => {
        if (show === index) {
            setShow(null);  // Si ya está mostrando los detalles, ocultarlos
        } else {
            setShow(index); // Mostrar los detalles del índice específico
        }
    };

    const toggleItemSelection = (item) => {

        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const medio_de_pago = [
        { label: "Efectivo", value: "Efectivo" },
        { label: "Tarjeta Debito", value: "Tarjeta Debito" },
        { label: "Tarjeta Credito", value: "Tarjeta Credito" },
        { label: "Nequi", value: "Nequi" },
        { label: "DaviPlata", value: "DaviPlata" },
        { label: "Plataforma de Pagos", value: "Plataforma de Pagos" },




    ]

    const estado = [
        { label: "pendientes", value: "pendientes" },
        { label: "pagadas", value: "pagadas" },
        { label: "acuerdo de Pago", value: "acuerdoPago" },
        { label: "anuladas", value: "anuladas" }




    ]





    const toggleVentana = () => {
        console.log(valorInicial);
        setMostrarVentana(!mostrarVentana);

    };

    const toggleVentanaPago = () => {
        setMostrarVentanaPago(!mostrarVentanaPago);

    };

    const handleClick = () => {
        setIsClicked(!isClicked);

    };

    const handleClickFactura = () => {
        navigate('/edit', { state: { detail } });
    };

    const handleTextoChange = (event) => {
        setTexto(event.target.value);
    };

    const handlePagarClick = () => {
        // Capturar y procesar los datos de las facturas
        const datosFacturas = searchResults.map((factura) => {
            return { ...factura };
        });;

        setDatosFacturas(datosFacturas);
        console.log('Datos de las facturas:', datosFacturas);


        // Establecer la factura seleccionada en el estado

    };

    const [datosFacturas, setDatosFacturas] = useState([]);






    const handleSearch = () => {
        console.log("Iniciando búsqueda con documento:", detail?.documento);

        const db = getDatabase();
        const dbRef = ref(db, 'facturas/pendientes');
        const nodoQuery = query(dbRef, orderByKey(), equalTo(detail?.documento));

        onValue(nodoQuery, (snapshot) => {
            const data = snapshot.val();
            let results = [];

            if (data) {
                results = Object.values(data);
            }

            console.log("Resultados procesados:", results);
            setSearchResults(results);
            setShowResults(true);
        }, (error) => {
            console.error("Error al obtener datos de Firebase:", error);
            toast.error('Error al obtener resultados. Intenta nuevamente.');
        });
    };


    const guardarDatos = async (e) => {
        e.preventDefault();

        

        try {
            if (!detail?.documento) {
                throw new Error("No se ha proporcionado el documento para la factura.");
            }

            let nodoOrigen, nodoDestino;

            switch (valorInicial.estado) {
                case "pendiente":
                case "pendientes":
                    nodoOrigen = `facturas/pendientes/${detail.documento}/${valorInicial.estado}`;
                    nodoDestino = `facturas/pagadas/${detail.documento}/${valorInicial.estado}`;
                    break;
                case "pagada":
                case "pagadas":
                    nodoOrigen = `facturas/pagadas/${detail.documento}/${valorInicial.estado}`;
                    nodoDestino = `facturas/acuerdoPago/${detail.documento}/${valorInicial.estado}`;
                    break;
                case "acuerdoPago":
                case "acuerdosPago":
                    nodoOrigen = `facturas/acuerdoPago/${detail.documento}/${valorInicial.estado}`;
                    nodoDestino = `facturas/anuladas/${detail.documento}/${valorInicial.estado}`;
                    break;
                case "anulada":
                case "anuladas":
                    nodoOrigen = `facturas/anuladas/${detail.documento}/${valorInicial.estado}`;
                    nodoDestino = `facturas/pendientes/${detail.documento}/${valorInicial.estado}`;
                    break;
                default:
                    throw new Error(`Estado no válido: ${valorInicial.estado}`);
            }
            

            const db = getDatabase();
            const nodoOrigenRef = ref(db, nodoOrigen);
            const nodoDestinoRef = ref(db, nodoDestino);

            // Obtener datos de la factura desde el nodo original
            const snapshot = await get(nodoOrigenRef);
            const factura = snapshot.val();

            

            if (!factura) {
                throw new Error(`No se encontró la factura en el nodo ${nodoOrigen}`);
            }

            // Guardar la factura en el nodo destino
            await set(nodoDestinoRef, factura);

            // Eliminar la factura del nodo original si es diferente al nodo destino
            if (nodoOrigen !== nodoDestino) {
                await remove(nodoOrigenRef);
            }

            // Limpiar el estado después de guardar
            setValorInicial((prevState) => ({
                ...prevState,
                codigo: "",
                nuevoEstado: "",
                medio_de_pago: "",
                // Añade otros campos que necesitas limpiar
            }));
            console.log(setValorInicial);

            alert("Factura actualizada correctamente");

        } catch (error) {
            console.error("Error al guardar en Firebase:", error);
            alert("Error en factura: " + error.message);
        }
    };





    const handleSelectMedioDePago = ({ value }) => {
        setValorInicial((prevState) => ({
            ...prevState,
            medio_de_pago: value
        }));


    };

    const [valorInicial, setValorInicial] = useState({
        fechaCompleta: "",
        fechaLimitePago: "",
        chat: "",
        llamada: "",

        cedula: detail?.documento,
        primerNombre: detail?.primerNombre,
        segundoNombre: detail?.segundoNombre,
        primerApellido: detail?.primerApellido,
        segundoApellido: detail?.segundoApellido,
        mensualidad: "",
        estado: "",
        medio_de_pago: "",
        comentarios: "",
        total: "",
        usuario: userLogin,

    });

    const handleSelectEstado = ({ value }) => {
        setValorInicial((prevState) => ({
            ...prevState,
            estado: value
        }));


    };










    return (
        <>
            <div className="w-full sm:px-6">
                <ToastContainer />
                <div className="px-4 md:px-10 py-4 md:py-7 bg-pack-six bg-cover bg-center rounded-tl-lg rounded-tr-lg">
                    <div className="sm:flex items-center justify-between">
                        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Factura</p>
                        <div className="flex justify-end">



                        </div>
                    </div>
                </div>

                <div className="ml-1 mb-14 mt-10 flex justify-around w-full h-auto">
                    <div className="bg-white border-t-4 border-green-400 rounded-lg p-4 md:p-6 shadow-md w-96">
                        <div className="flex justify-between items-center">
                            <img className="w-16 h-16" src={user} alt="User" />
                            <h2 className="text-lg font-bold text-green-400">ACTIVO</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Contrato</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">COL_4001</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Titular</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">
                                {`${detail?.primerNombre ?? ''} ${detail?.segundoNombre ?? ''} ${detail?.primerApellido ?? ''} ${detail?.segundoApellido ?? ''}`}
                            </h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Niup</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">{detail?.documento}</h2>
                        </div>
                        <div className="flex items-center mt-4">
                            <h2 className="text-base font-bold text-gray-900">Direccion</h2>
                        </div>
                        <div>
                            <h2 className="text-base dark:text-gray-100 text-black">{detail?.direccion}</h2>
                        </div>
                    </div>

                    <div className="bg-white border-t-4 border-red-400 rounded-lg p-4 md:p-6 shadow-md w-96">


                        {datosFacturas.map((factura, index) => (


                            <div key={index} className=" pb-2 text-gray-700">
                                <div className="flex justify-between items-center">
                                    <img className="w-16 h-16" src={contact} alt="Contacto" />

                                    {Object.keys(factura).map((key, i) => (
                                        <p className='text-xl text-red-400 ' key={i}><strong>#: </strong> {String(factura[key].codigo)}</p>
                                    ))}
                                </div>
                                {Object.keys(factura).map((key, i) => (
                                    <p className='mt-10 text-lg ' key={i}><strong>Estado: </strong> {String(factura[key].estado)}</p>
                                ))}
                                {Object.keys(factura).map((key, i) => (
                                    <p className='mt-10 text-lg' key={i}><strong>Plan:</strong> {String(factura[key].plan)}</p>
                                ))}
                                {Object.keys(factura).map((key, i) => (
                                    <p className='mt-10 text-lg' key={i}><strong className='text-xl text-red-500 mt-10'>Pagar: $</strong> {String(factura[key].Total)}</p>
                                ))}


                            </div>


                        ))}

                        <div
                            id="medio de pago"
                            className="block border-b  pb-2.5 pt-4 w-72 text-lg text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                        >
                            <Select
                                defaultValue={{ label: "Medio de Pago", value: "Medio de Pago" }}
                                options={medio_de_pago}
                                onChange={handleSelectMedioDePago}
                            />
                        </div>

                        <div
                            id="estado"
                            className="block border-b  pb-2.5 pt-4 w-72 text-lg text-gray-700 bg-transparent rounded-lg appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer relative"
                        >
                            <Select
                                defaultValue={{ label: "Estado", value: "Estado" }}
                                options={estado}
                                onChange={handleSelectEstado}
                            />
                        </div>

                        <div className="mt-10 mr-10">
                            <button
                                className="btn w-80  bg-red-400 p-4 rounded col-span-4"
                                onClick={toggleVentana}
                            >
                                Pagar
                            </button>


                            {mostrarVentana && (
                                <div className="fixed inset-0 flex items-center justify-center">

                                    <div className="bg-gray-800 bg-opacity-50 absolute inset-0 backdrop-filter backdrop-blur-sm"></div>
                                    <div className="bg-white p-4 rounded-lg shadow-lg z-10 w-96 h-72">

                                        <h3 className="text-lg font-bold mb-2 text-center">Seguro </h3>
                                        <h3 className="text-base mb-2 text-center">SE VA A GENERAR EL PAGO</h3>
                                        <div className="relative">
                                            <div className="absolute left-3 top-3 text-gray-400">
                                                <BsCurrencyDollar size={18} />
                                            </div>
                                            <input
                                                className="w-full border rounded-lg p-2 mb-4 pl-10" // Aumenta el valor de pl para dejar más espacio al icono
                                                placeholder="Ingrese valor "
                                                value={texto}
                                                onChange={handleTextoChange}
                                            />
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="">
                                                <button
                                                    className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                                    onClick={() => {
                                                        toggleVentana();
                                                        toast.error('Recuerda abrir caja para facturar', { position: toast.POSITION.BOTTOM_RIGHT });
                                                    }}
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                            <div className="">
                                                <button
                                                    className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                                    onClick={(e) => {
                                                        guardarDatos(e);
                                                        toast.success('Base actualizada', { position: toast.POSITION.BOTTOM_RIGHT });
                                                    }}
                                                >
                                                    Aceptar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="bg-white border-t-4 border-blue-400 rounded-lg p-4 md:p-6 shadow-md w-96">
                        <div className="calculator bg-white border-2 border-gray-500/40 shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                            <div className="display bg-white border-2 border-gray-500-40 p-4 rounded text-right text-2xl mb-4">
                                {input}
                            </div>
                            <div className="buttons grid grid-cols-4 gap-4">
                                {['7', '8', '9', '/'].map(char => (
                                    <button key={char} className="btn bg-yellow-300/40 p-4 rounded" onClick={() => handleButtonClick(char)}>{char}</button>
                                ))}
                                {['4', '5', '6', '*'].map(char => (
                                    <button key={char} className="btn bg-yellow-300 p-4 rounded" onClick={() => handleButtonClick(char)}>{char}</button>
                                ))}
                                {['1', '2', '3', '-'].map(char => (
                                    <button key={char} className="btn bg-yellow-300/40 p-4 rounded" onClick={() => handleButtonClick(char)}>{char}</button>
                                ))}
                                {['0', '.', '=', '+'].map(char => (
                                    <button
                                        key={char}
                                        className="btn bg-yellow-300 p-4 rounded"
                                        onClick={char === '=' ? handleCalculate : () => handleButtonClick(char)}
                                    >
                                        {char}
                                    </button>
                                ))}
                                <button
                                    className="btn bg-blue-400 p-4 rounded col-span-4"
                                    onClick={handleClear}
                                >
                                    Borrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="justify-end">
                    <button
                        className="bg-gradient-to-r from-orange-400 to-orange-500 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                        onClick={() => {
                            handleSearch();

                        }}
                    >
                        Actualizar
                    </button>
                </div>


                <div className="bg-white border-2 border-yellow-500/40 shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
                    <table className="w-full whitespace-nowrap">
                        <thead>
                            <tr className="h-16 w-full text-sm leading-none text-gray-800">
                                <th className="font-normal text-center pl-1">Codigo</th>
                                <th className="font-normal text-center pl-12">Creada</th>
                                <th className="font-normal text-center pl-12">Finaliza</th>
                                <th className="font-normal text-center pl-12">Estado</th>
                                <th className="font-normal text-center pl-20">Plan</th>

                                <th className="font-normal text-center pl-16">Usuario</th>
                                <th className="font-normal text-center pl-16">Total</th>
                                {/* Agrega más columnas según los datos que desees mostrar */}
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.map((factura, index) => (
                                Object.keys(factura).map((key) => (
                                    <tr key={key} onClick={handlePagarClick} className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-yellow-400/60 border-b border-t border-gray-300">
                                        <td className="pl-4">{factura[key].codigo}</td>
                                        <td className="pl-12">{factura[key].fechaFacturacion} </td>
                                        <td className="pl-12">{factura[key].fechaLimitePago} </td>
                                        <td className="pl-12">{factura[key].estado}</td>
                                        <td className="pl-20">{factura[key].plan}</td>
                                        <td className="pl-16">{factura[key].usuario}</td>
                                        <td className="pl-16">{factura[key].total}</td>
                                        {/* Agrega más celdas según los campos que desees mostrar */}
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </table>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                        Pagar
                    </button>
                </div>


            </div>







        </>
    );
}
