import React, { useState, useEffect } from "react";
import { getAuth, signOut } from 'firebase/auth';
import { db } from "../../firebase";
import { storage } from "../../firebase";
import { dbFirestore } from "../../firebase";
import { collection, addDoc, getDocs, where, doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes } from "firebase/storage";
import { FaAngleDown, FaWifi, FaStoreAlt } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import { uid } from "uid";
import { v4 as uuidv4 } from 'uuid'
import { getDatabase, set, ref as dbRef, push, orderByChild, get, equalTo, query, onValue, update } from "firebase/database";
import { useDropzone } from 'react-dropzone';
import { IoMdAdd, IoMdTrash } from 'react-icons/io';
import { IoSearchOutline, IoSendOutline } from "react-icons/io5"
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import netflix from "../../assets/netflix.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import amazon from "../../assets/amazon.png";
import perfileUser from "../../assets/perfileUser.png";
import disney from "../../assets/disney.png";
import win from "../../assets/win.png";
import colnet from "../../assets/colnet.png";
import { FiSave, FiX, FiPrinter } from "react-icons/fi";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdManageAccounts } from "react-icons/md";
import { BsCurrencyDollar } from 'react-icons/bs';








export default function NewColaborate(props) {

    const userLogin = props.user;
    console.log(userLogin);

    const cities = [
        'Bogotá',
        'Medellín',
        'Cali',
        'Barranquilla',
        'Cartagena',
        'Chiquinquira',
        'Simijaca',
        'Santa Sofia',
        'Ubate',
        'Zipaquira',
        'Cajica',

    ];

    const [selectedCity, setSelectedCity] = useState('');
    const [user, setUser] = useState(userLogin);

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };


    const sedesData = [
        'Oficina Principal',
        'Oficina Centro',
        'Oficina Sur',

    ];

    const [selectedSede, setSelectedSede] = useState('');

    const handleSedeChange = (event) => {
        setSelectedSede(event.target.value);
    };





    const [texto, setTexto] = useState('');
    const [correoTxt, setCorreoTxt] = useState('');
    const [correoExiste, setCorreoExiste] = useState(false);
    const [nombreTxt, setNombreTxt] = useState('');
    const [documentoTxt, setDocumentoTxt] = useState('');
    const [contactoTxt, setContactoTxt] = useState('');
    const [passwordTxt, setPasswordTxt] = useState('');
    const [adressTxt, setAdressTxt] = useState('');
    const [selectedOptionServer, setSelectedOptionServer] = useState('');
    const [selectedOptionActive, setSelectedOptionActive] = useState('');

    const [selectedOptionTecnico, setSelectedOptionTecnico] = useState('');



    const [showInput, setShowInput] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenApp, setIsOpenApp] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionApp, setSelectedOptionApp] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [file, setFile] = useState(null);
    const [fileRp, setFileRp] = useState(null);
    const [filePerfil, setFilePerfil] = useState(null);
    const [searchDoc, setSearchDoc] = useState("");
    const [complementos, setComplementos] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [pais, setPais] = useState('');
    const [clickCount, setClickCount] = useState(0);

    const [contrato, setContrato] = useState("");
    const [lastContract, setLastContract] = useState(0)

    const [newContractNumber, setNewContractNumber] = useState(null);
    const [newContractUpdated, setNewContractUpdated] = useState('');


    const createNewRole = async (databasePermissions) => {
        if (!documentoTxt || !nombreTxt || !correoTxt || !contactoTxt || !selectedCity || !selectedSede || !selectedOptionTecnico || !selectedOptionActive) {
            toast.error("Todos los campos son requeridos.");
            return;
        }

        try {
            // Crear o usar una subcolección dentro del documento de roles específico
            const rolesCollectionRef = doc(dbFirestore, 'roles', documentoTxt);

            // Agregar un nuevo documento a esta subcolección
            await setDoc(rolesCollectionRef, {
                correoTxt,
                nombreTxt,
                documentoTxt,
                contactoTxt,
                passwordTxt,
                adressTxt,
                selectedOptionTecnico,
                permissions: databasePermissions,
                selectedCity,
                selectedSede
            });
            console.log('Nuevo rol creado con ID:', rolesCollectionRef.id);

            // Establecer permisos relacionados
            await setDoc(doc(dbFirestore, "permissions", rolesCollectionRef.id), {
                userPermissions: databasePermissions
            });

        } catch (error) {
            console.error('Error al crear el nuevo rol:', error);
            throw error;
        }
    };




    const verificarExistenciaCorreo = async (correo) => {
        try {
            if (!correo) {
                throw new Error("No se ha proporcionado un correo electrónico.");
            }
            const q = query(collection(dbFirestore, 'roles'), where('correoTxt', '==', correo));
            const querySnapshot = await getDocs(q);
            return !querySnapshot.empty;
        } catch (error) {
            console.error('Error al verificar existencia de correo:', error);
            throw error;
        }
    };

    const handleCorreoChange = async (event) => {
        const correo = event.target.value;
        setCorreoTxt(correo);

    };












    const validateUser = async (email) => {
        try {
            const user = getAuth().currentUser;

            if (user && user.email === email) {
                // El usuario está autenticado y el correo coincide con el registrado en el nodo "roles"
                return true;
            } else {
                // El usuario no está autenticado o el correo no coincide
                return false;
            }
        } catch (error) {
            console.error('Error al validar el usuario:', error);
            throw error;
        }
    };

    const handleSubmit = async () => {
        try {
            // Intenta crear el rol y maneja la respuesta
            await handleRolUsers();
            toast.success('Usuario creado correctamente');
        } catch (error) {
            // Maneja los errores mostrando un toast de error
            console.error('Error al crear el usuario:', error);
            toast.error(error.message);
        }
    };

    




    const handleClickAdmin = async () => {
        try {
            const currentUser = getAuth().currentUser;

            if (currentUser) {
                const isUserRegistered = await validateUser(currentUser.email);

                if (isUserRegistered) {
                    const querySnapshot = await getDocs(collection(dbFirestore, 'roles'));
                    const roles = [];

                    querySnapshot.forEach((doc) => {
                        const role = doc.data();
                        role.id = doc.id;
                        roles.push(role);
                    });

                    const tecnicoRoles = roles.filter((role) => {
                        return (
                            role.correoTxt === currentUser.email &&
                            role.selectedOptionTecnico === 'Administrador'
                        );
                    });

                    if (tecnicoRoles.length > 0) {
                        toast.success("Bienvenido" + tecnicoRoles)

                    } else {
                        throw new Error('El usuario no tiene permisos de administrador');
                    }
                } else {
                    throw new Error('El usuario no está registrado');
                }
            } else {
                throw new Error('El usuario no está autenticado');
            }
        } catch (error) {
            toast.error('Error al validar el usuario o verificar los roles: ' + error.message);
        }
    };



    const handleRolUsers = async () => {
        // Asegurarse de que todos los campos requeridos están completos antes de proceder
        if (!documentoTxt || !correoTxt || !nombreTxt || !contactoTxt || !selectedOptionTecnico || !selectedCity || !selectedSede) {
            throw new Error('Todos los campos deben estar completos.');
        }
    
        // Intentar crear el nuevo rol
        try {
            // Llamada a la función para crear un nuevo rol en la base de datos
            await createNewRole(); // Asegúrate de que esta función también maneje y lance errores correctamente
            return true; // Devuelve true si el rol se crea sin errores
        } catch (error) {
            // Lanza un error si la creación del rol falla
            throw new Error('Error al crear el usuario: ' + error.message);
        }
    };






    const handleNombreChange = (event) => {
        setNombreTxt(event.target.value);
    };
    const handleDocumentoChange = (event) => {
        setDocumentoTxt(event.target.value);
    };
    const handleContactoChange = (event) => {
        setContactoTxt(event.target.value);
    };

    const handlePasswordUsersChange = (event) => {
        setPasswordTxt(event.target.value);
    };

    const handleAdressChange = (event) => {
        setAdressTxt(event.target.value);
    };

    const handleTextoChange = (event) => {
        setTexto(event.target.value);
    };











    const onDrop = (acceptedFiles) => {
        // Obtener el archivo seleccionado
        const selectedFile = acceptedFiles[0];
        // Actualizar el estado del archivo
        setFile(selectedFile);
    };

    const onDropRp = (acceptedFiles) => {
        // Obtener el archivo seleccionado
        const selectedFileRp = acceptedFiles[0];
        // Actualizar el estado del archivo
        setFileRp(selectedFileRp);
    };
    const onDropPerfil = (acceptedFiles) => {
        // Obtener el archivo seleccionado
        const selectedFilePerfil = acceptedFiles[0];
        // Actualizar el estado del archivo
        setFilePerfil(selectedFilePerfil);
    };

    const folderName = 'cedula';
    const folderNameRp = 'recibo';
    const folderNamePerfil = 'foto de perfil';

    const uploadImage = async (file, documento) => {

        const fileName = documento
        const storageRef = ref(storage, `${folderName}/${fileName}`);

        await uploadBytes(storageRef, file);
        return true;


    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleOptionChangeActive = (e) => {
        setSelectedOptionActive(e.target.value);
    };

    const handleOptionChangeTecnico = (e) => {
        setSelectedOptionTecnico(e.target.value);
    };


    const uploadImagePerfil = async (filePerfil, documentoTxt) => {

        const fileNamePerfil = documentoTxt
        const storageRef = ref(storage, `${folderNamePerfil}/${fileNamePerfil}`);

        await uploadBytes(storageRef, filePerfil);
        return true;




    }






    const handleUploadPerfil = () => {
        if (filePerfil && documentoTxt) {
            uploadImagePerfil(filePerfil, documentoTxt);
            toast.success("Imagen subida exitosamente");
        } else {
            toast.error("Error al subir la imagen");
            console.log("Debes seleccionar una imagen y un nombre");
        }
    };













    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { getRootProps: getRootProps2, getInputProps: getInputProps2, isDragActive: isDragActive2 } = useDropzone({ onDrop: onDropRp });
    const { getRootProps: getRootProps3, getInputProps: getInputProps3, isDragActive: isDragActive3 } = useDropzone({ onDrop: onDropPerfil });


















    return (
        <>
            <div className="flex items-center justify-center" >

                <ToastContainer />
                <div className="">



                    <div className="  justify-between mt-7" >



                        <div className=" flex items-center justify-center">



                            <div className=" w-auto h-auto">

                                <div className="px-4 md:px-10 py-4 md:py-7 bg-pack-six bg-cover bg-center  rounded-tl-lg rounded-tr-lg">
                                    <div className="sm:flex items-center justify-between">
                                        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">Asignacion</p>

                                    </div>
                                </div>


                                <h3 className="text-2xl mt-12 mb-2 text-center ">Confirma datos con el Colaborador</h3>
                                <div className=" flex justify-center mt-12 ">
                                    <img className=" h-32 items-center  w-32" src={perfileUser}></img>

                                </div>



                                <form onSubmit={handleSubmit} >


                                    <div className="relative mt-12 text-center">
                                        <div className="relative inline-flex w-full justify-center p-10 mb-4 pl-10">
                                            <div>
                                                <label htmlFor="correoTxt" className=" text-center flex justify-start text-gray-700">Nombre Completo:</label>
                                                <input
                                                    id="nombreTxt"
                                                    className="w-60 border border-blue-500/75 focus:border-blue-500 rounded-lg p-2 mr-44 mb-4 pl-5"
                                                    required
                                                    value={nombreTxt}
                                                    onChange={handleNombreChange}
                                                />

                                            </div>
                                            <div>
                                                <label htmlFor="nombreTxt" className=" text-center flex justify-start text-gray-700">Correo Electrónico:</label>
                                                <input
                                                    id="correoTxt"
                                                    className="w-60 border border-blue-500/75 rounded-lg p-2 mr-5 mb-4 pl-5"
                                                    required
                                                    value={correoTxt}
                                                    onChange={handleCorreoChange}
                                                    type="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="relative inline-flex w-full justify-center p-2 mb-10 pl-1">
                                            <div>
                                                <label htmlFor="documentoTxt" className="flex justify-start text-gray-700">Documento de Identidad:</label>
                                                <input
                                                    id="documentoTxt"
                                                    className="w-60 border border-blue-500/75 rounded-lg p-2 mr-44 mb-4 pl-5"
                                                    required
                                                    value={documentoTxt}
                                                    onChange={handleDocumentoChange}
                                                />

                                            </div>
                                            <div>
                                                <label htmlFor="contactoTxt" className="flex justify-start text-gray-700">Número de Contacto:</label>
                                                <input
                                                    id="contactoTxt"
                                                    className="w-60 border border-blue-500/75 rounded-lg p-2 mr-5 mb-4 pl-5"

                                                    value={contactoTxt}
                                                    type="number"
                                                    onChange={handleContactoChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="relative inline-flex w-full justify-center p-2 mb-4 pl-1">
                                            <div>
                                                <label htmlFor="citySelector" className="flex justify-start text-gray-700">Ciudad:</label>
                                                <select
                                                    id="citySelector"
                                                    className="w-60 border border-blue-500/75 rounded-lg p-2 mr-44 mb-16 pl-2"
                                                    value={selectedCity}
                                                    onChange={handleCityChange} v
                                                    required
                                                >
                                                    {cities.map((city) => (
                                                        <option key={city} value={city}>
                                                            {city}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="sedeSelector" className="flex justify-start text-gray-700">Sede:</label>
                                                <select
                                                    id="sedeSelector"
                                                    className="w-60 border border-blue-500/75 rounded-lg p-2 mr-5 mb-16 pl-2"
                                                    value={selectedSede}
                                                    onChange={handleSedeChange}
                                                    required
                                                >
                                                    {sedesData.map((sede) => (
                                                        <option key={sede} value={sede}>
                                                            {sede}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        <div className="relative inline-flex w-full justify-center p-2 mb-20 pl-1 ">


                                            <div className="relative inline-flex flex-col  w-64 justify-center p-2 mb-4 mr-44 pl-1">
                                                <select
                                                    required
                                                    className="w-full appearance-none bg-white border border-blue-500/75   rounded-lg px-4 py-2 pr-4 shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    value={selectedOptionTecnico}
                                                    onChange={handleOptionChangeTecnico}

                                                >
                                                    <option value="">Tipo de Colaborador</option>
                                                    <option value="Administrador">Administrador</option>
                                                    <option value="Caja">Caja</option>
                                                    <option value="Tecnicos">Tecnicos</option>
                                                    <option value="Soporte Tecnico">Soporte Tecnico</option>
                                                    <option value="Vendedor">Vendedor</option>
                                                </select>
                                                <div className="pointer-events-none border-blue-500/75 absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path fillRule="evenodd" d="M6 8l4 4 4-4H6z" />
                                                    </svg>
                                                </div>
                                            </div>

                                            <div className="relative inline-flex flex-col w-64 justify-center  mb-4 pl-1">
                                                <select
                                                    required
                                                    className="w-full appearance-none border border-blue-500/75 rounded-lg bg-white hover:border-gray-500 px-4 py-2 pr-4  shadow leading-tight focus:outline-none focus:shadow-outline"
                                                    value={selectedOptionActive}
                                                    onChange={handleOptionChangeActive}

                                                >
                                                    <option value="">Permisos</option>
                                                    <option value="'create', 'read', 'update', 'delete'">Administrador</option>
                                                    <option value="television">Facturacion</option>
                                                    <option value="Internet y television">Vendedor</option>
                                                    <option value="Plataformas Streaming">Soporte Tecnico</option>
                                                </select>
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                        className="fill-current h-4 w-4"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path fillRule="evenodd" d="M6 8l4 4 4-4H6z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>




                                    </div>






                                    <div className="container flex justify-center w-full pb-4">

                                        <div
                                            {...getRootProps3()}
                                            className={`py-5 flex items-center text-center justify-center h-32 w-96  ${isDragActive2 ? 'border-blue-500' : 'border-gray-400'
                                                }`}
                                        >

                                            <label htmlFor="Recibo Publico" className="flex flex-col items-center justify-center w-96 h-32 border-2 border-blue-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-blue-100 dark:border-blue-600 dark:hover:border-blue-500 dark:hover:bg-blue-600">
                                                {!filePerfil ? (
                                                    <div className="flex flex-col items-center justify-center ">
                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Cargar Documentos</span> Foto de perfil</p>
                                                    </div>
                                                ) : null}

                                                <input {...getInputProps3()} />
                                                {filePerfil ? (
                                                    <>
                                                        <div className="flex flex-col  py-10 items-center justify-center h-screen">
                                                            <div className=" py-10">
                                                                <p className="mt-32">{filePerfil.name}</p>
                                                            </div>
                                                            <div className="flex items-center gap-1">
                                                                {filePerfil.type === 'application/pdf' ? (
                                                                    <iframe src={URL.createObjectURL(filePerfil)} title={filePerfil.name} className="w-full h-24" />
                                                                ) : (
                                                                    <img src={URL.createObjectURL(filePerfil)} alt={filePerfil.name} className="w-full h-24 object-contain" />
                                                                )}
                                                            </div>

                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="flex items-center ">
                                                        <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M14 18H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2zM6 4v12h8V4H6z" clipRule="evenodd" />
                                                        </svg>
                                                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                    </div>
                                                )}



                                            </label>
                                        </div>

                                    </div>

                                </form>


                                <div className="flex justify-center mb-20 mt-16 space-x-10">
                                    <div className="">
                                        <button
                                            className="bg-gradient-to-r from-red-500 to-pink-500 hover:bg-gradient-to-r hover:from-red-600 hover:to-pink-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={() => {

                                                toast.error('Error creacion de usuario cancelado');
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                    <div className="mr-10">
                                        <button
                                            className="bg-gradient-to-r from-blue-500 to-indigo-500 hover:bg-gradient-to-r  hover:from-blue-500 hover:to-indigo-600 text-white rounded-lg px-14 py-2 mt-4"
                                            onClick={handleSubmit}
                                        >
                                            Aceptar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>













                </div>

            </div>
        </>
    );
}


