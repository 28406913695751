import React from 'react'
import colnet from "../../assets/colnet.png"

export default function Factura() {


  const handlePrint = () => {
    window.print()
  }

  return (


    <div className="bg-white  rounded-xl inset-0 z-50 right-100 mx-16 my-5 shadow-md">


      <div className="border-2 flex items-center justify-between border-blue-300 ">
        <h1 className="text-xl font-medium flex bg-blue-400 text-white justify-start items-start ">Estado de Cuenta</h1>
        <h1 className="text-xl font-medium flex px-2 justify-end items-end ">COL_4001</h1>
      </div>

      <div className="flex  items-center rounded-2xl border-dashed border-2 border-blue-300  justify-between">

        <img src={colnet} alt="" className="w-46 h-36" />
        <h2 className='px-2 text-red-300 text-3xl font-extrabold'>$40.000</h2>
      </div>

      <div className=" flex justify-between mb-5">
        <div className="w-1/2">
          <p className="font-medium mb-2">De:</p>
          <p className="mb-2">Tu Nombre</p>
          <p className="mb-2">Tu Dirección</p>
          <p className="mb-2">Tu Ciudad, Estado</p>
        </div>
        <div className="w-1/2">
          <p className="font-medium mb-2">Para:</p>
          <p className="mb-2">Nombre del cliente</p>
          <p className="mb-2">Dirección del cliente</p>
          <p className="mb-2">Ciudad del cliente, Estado</p>
        </div>
      </div>
      <table className="w-full text-left mb-5 border-2 border-blue-400">
        <thead className="bg-blue-200">
          <tr className="text-sm font-medium">
            <th className="p-3">Producto</th>
            <th className="p-3">Precio</th>
            <th className="p-3">Cantidad</th>
            <th className="p-3">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-t border-blue-300">
            <td className="p-3">Producto 1</td>
            <td className="p-3">$10.00</td>
            <td className="p-3">2</td>
            <td className="p-3">$20.00</td>
          </tr>
          <tr className="border-t border-blue-300">
            <td className="p-3">Producto 2</td>
            <td className="p-3">$5.00</td>
            <td className="p-3">1</td>
            <td className="p-3">$5.00</td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-end mb-7 ">
        <p className="font-medium mr-5 border-dotted border-2 border-blue-300">Subtotal: $25.00</p>
        <p className="font-medium mr-5 border-dotted border-2 border-blue-300">IVA (12%): $3.00</p>
        <p className="font-medium border-dotted border-2 border-blue-300">Total: $28.00</p>
      </div>

      <div className="border-2 border-blue-400 rounded-xl w-full mb-7 mx-auto px-4 h-52">

      </div>

      <div className="border-2 border-dotted border-blue-400 mb-7 w-full py-5 mx-auto px-4 h-52">

      </div>

 

      <div className="py-12 flex justify-end">



        <button type="button" className="px-10 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm  py-2.5 text-center mr-10 mb-2">Cancelar</button>
        <button onClick={handlePrint} type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-10 mb-2">Imprimir</button>
        <button type="button" className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-10 py-2.5 text-center mr-10 mb-2">Guardar</button>



      </div>
    </div>








  )

}

