import React, { useState } from 'react';
import {
    FaUser,
    FaSearch,
    FaUserFriends,
    FaHistory,
    FaPaperclip,
    FaSmile,
    FaPaperPlane,
    FaCheckCircle,
} from 'react-icons/fa';
import colnet from '../../assets/colnet.png';

const Chat = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageInput, setMessageInput] = useState("");

  const chats = [
    {
      id: 1,
      name: "Chat 1",
      phoneNumber: "1234567890",
      requirement: "Requerimiento 1",
      messages: [{ text: "Mensaje 1", time: "10:00 AM", sent: true }],
    },
    {
      id: 2,
      name: "Chat 2",
      phoneNumber: "0987654321",
      requirement: "Requerimiento 2",
      messages: [{ text: "Mensaje 2", time: "11:30 AM", sent: false }],
    },
    {
      id: 3,
      name: "Chat 3",
      phoneNumber: "9876543210",
      requirement: "Requerimiento 3",
      messages: [
        { text: "Mensaje 3", time: "12:45 PM", sent: true },
      ],
    },
  ];

  const handleChatSelection = (chat) => {
    setSelectedChat(chat);
  };

  const handleSearch = (event) => {
    // Implementa la lógica de búsqueda
  };

  const handleInputChange = (event) => {
    setMessageInput(event.target.value);
  };

  const handleSendMessage = () => {
    // Implementa la lógica de envío del mensaje
  };

  return (
    <div className="flex mt-8 h-screen w-auto shadow-lg shadow-blue-400 border-spacing-11 rounded-2xl border-4-blue-900 border">
      <div className="w-1/4 border-spacing-x-2 bg-gradient-to-b from-white via-white to-white rounded-lg shadow-lg shadow-blue-300">
        <nav className="flex items-center  bg-gradient-to-r from-white via-white to-white p-4">
          <FaUser className="mr-2" />
          <h2 className="font-bold">Conversaciones Activas</h2>
        </nav>
        <div className="p-4 ">
          <div className="text-center items-center">
            <img className="w-34 h-32" src={colnet} alt="Colnet" />
          </div>
          <div className="flex items-center bg-white rounded-full border-2 outline-2 p-2 mb-4">
            <input
              type="text"
              placeholder="Buscar"
              onChange={handleSearch}
              className="flex-grow bg-transparent outline-none"
            />
            <FaSearch className="text-gray-500" />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-white rounded-lg shadow p-4 flex flex-col items-center justify-center">
              <FaUserFriends className="text-3xl text-blue-500 mb-2" />
              <h3 className="text-lg font-bold">Activos</h3>
            </div>
            <div className="bg-white rounded-lg shadow p-4 flex flex-col items-center justify-center">
              <FaUser className="text-3xl text-blue-500 mb-2" />
              <h3 className="text-lg font-bold">Asignados</h3>
            </div>
            <div className="bg-white rounded-lg shadow p-4 flex flex-col items-center justify-center">
              <FaHistory className="text-3xl text-blue-500 mb-2" />
              <h3 className="text-lg font-bold">Historial</h3>
            </div>
          </div>

          <div className="mt-4">
            <ul>
              {chats.map((chat) => (
                <li
                  key={chat.id}
                  onClick={() => handleChatSelection(chat)}
                  className={`cursor-pointer p-2 rounded-lg ${
                    selectedChat?.id === chat.id
                      ? "bg-gradient-to-b from-white via-white to-blue-300"
                      : ""
                  }`}
                >
                  <div className="flex items-center">
                    <FaUser className="text-blue-500 mr-2" />
                    <div>
                      <p className="text-lg font-bold">{chat.name}</p>
                      <p className="text-sm text-gray-500">{chat.phoneNumber}</p>
                      <p className="text-sm text-gray-500">{chat.requirement}</p>
                    </div>
                    <FaPaperclip className="text-gray-500 ml-auto" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-3/4 border-spacing-2 border-dashed border rounded-2xl bg-white p-4 flex flex-col justify-between">
        {selectedChat ? (
          <div className="mb-4">
            <nav className="flex items-center bg-gradient-to-b from-white via-white to-white">
              <h2 className="font-bold mb-4">{selectedChat.name}</h2>
            </nav>
            <div className="flex flex-col-reverse">
              {selectedChat.messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex ${
                    index % 2 === 0 ? "justify-end" : "justify-start"
                  } mb-4`}
                >
                  <div
                    className={`p-4 ${
                      index % 2 === 0
                        ? "bg-blue-100 text-white rounded-br-3xl"
                        : "bg-gray-200 text-gray-700 rounded-bl-3xl"
                    }`}
                  >
                    <div className="flex items-start flex-row-reverse gap-4">
                      <span className="bg-blue-100 text-blue-900 font-bold py-2 px-[10px] rounded-full">
                        JT
                      </span>
                      <div>
                        <p className="text-white bg-blue-600 p-4 mb-2 rounded-tl-2xl rounded-br-2xl rounded-bl-2xl">
                          Lorem Ipsum ha sido el texto de relleno estándar de las
                          industrias desde el año 1500
                        </p>
                        <span className="text-gray-400 text-xs flex justify-end">
                          09 septiembre 2021, 15:30 hrs
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500 flex-grow">
                        {message.text}
                      </p>
                      <p className="text-xs text-gray-500">{message.time}</p>
                      {message.sent && (
                        <FaCheckCircle className="text-green-500 ml-2" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>Selecciona una conversación</p>
        )}

        {selectedChat && (
          <div className="flex items-center mt-4">
            <FaPaperclip className="text-gray-500 h-14 cursor-pointer mr-2" />
            <FaSmile className="text-gray-500 h-14 cursor-pointer mr-2" />
            <input
              type="text"
              placeholder="Escribe un mensaje..."
              value={messageInput}
              onChange={handleInputChange}
              className="flex-grow bg-transparent outline-none rounded-full border h-14 border-gray-400 mr-2"
            />
            <FaPaperPlane
              className="text-blue-500 h-14 cursor-pointer"
              onClick={handleSendMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;